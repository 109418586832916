import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Closings } from 'src/app/model/agency.model';

@Component({
  selector: 'app-modal-close-extraordinary',
  templateUrl: './modal-close-extraordinary.component.html',
  styleUrls: ['./modal-close-extraordinary.component.css']
})
export class ModalCloseExtraordinaryComponent implements OnInit {

  minDate: Date = new Date();

  constructor(
    public dialogRef: MatDialogRef<ModalCloseExtraordinaryComponent>,
    private datePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public inputData: Closings
  ) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

  saveData() {
    let date = this.datePipe.transform(this.inputData.start_date, 'yyyy-MM-dd');
    this.inputData.start_date = date;

    let date2 = this.datePipe.transform(this.inputData.end_date, 'yyyy-MM-dd');
    this.inputData.end_date = date2;

    this.dialogRef.close(this.inputData);
  }
}


