import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-confirmation',
  templateUrl: './generic-confirmation.component.html',
  styleUrls: ['./generic-confirmation.component.scss']
})
export class GenericConfirmationComponent implements OnInit {

  message: string = "";

  constructor(
    public dialogRef: MatDialogRef<GenericConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.message = this.data.message;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(true);
  }

}
