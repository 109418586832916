import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MyErrorHandler } from 'src/app/constants/error';
import { GenericConfirmationComponent } from 'src/app/modal/generic-confirmation/generic-confirmation.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { EmailBlacklist, EmailPlaceholder, EmailSettings, EmailTemplate } from 'src/app/model/email-settings.model';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { BankService } from 'src/app/services/bank.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

interface AllowedPlaceholders {
  generic: string[],
  cassa: string[],
  gestore: string[]
}

@Component({
  selector: 'app-bank-email',
  templateUrl: './bank-email.component.html',
  styleUrls: ['./bank-email.component.scss']
})
export class BankEmailComponent implements OnInit, AfterViewInit  {

  dataSource = new MatTableDataSource();

  isLoading: boolean = true;
  showEmail: boolean = false;
  showNewBlacklistElement: boolean = false;

  model: EmailSettings = new EmailSettings();

  statusSelected: string = "INATTIVO";
  templateSelected: EmailTemplate = new EmailTemplate();
  allowedPlaceholders: AllowedPlaceholders = {
    generic: [],
    cassa: [],
    gestore: []
  };
  newBlacklistElement: EmailBlacklist = new EmailBlacklist();
  private toDeleteBlacklistElement: EmailBlacklist = new EmailBlacklist();

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(
    private router: Router,
    private bankService: BankService,
    private dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.showEmail = storageRoles.includes(ROLES.ADMIN) && !environment.isProd;
      this.initEmailInfo();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private initEmailInfo() {
    this.bankService.getEmailSettings().subscribe(
      data => {

        //STATUS

        this.model.status = data.status;
        this.statusSelected = this.model.status ? "ATTIVO" : "INATTIVO";

        //TEMPLATES

        data.templates.forEach((el: any) => {
          let templateTmp: EmailTemplate = new EmailTemplate();
          templateTmp.id = el.id;
          templateTmp.name = el.name;
          templateTmp.template = el.value;
          templateTmp.type = el.flow;

          this.model.templates.push(templateTmp);
        });

        //PLACEHOLDERS

        data.placeholders.forEach((el: any) => {
          let placeholderTmp: EmailPlaceholder = new EmailPlaceholder();
          placeholderTmp.name = el.value;
          placeholderTmp.type = el.flow;

          this.model.placeholders.push(placeholderTmp);

          switch(el.flow) {
            case "GENERIC": {
              this.allowedPlaceholders.generic.push(el.value);
              break;
            }

            case "CASSA": {
              this.allowedPlaceholders.cassa.push(el.value);
              break;
            }

            case "GESTORE": {
              this.allowedPlaceholders.gestore.push(el.value);
              break;
            }

            default: {
              console.error("Unknown type " + el.type);
              break;
            }
          }
        });

        //BLACKLIST

        data.blacklist.forEach((el: any) => {
          let blacklistTmp: EmailBlacklist = new EmailBlacklist();
          blacklistTmp.match = el.match;
          blacklistTmp.isFullmatch = el.full_match;

          this.model.blacklist.push(blacklistTmp);
        });

        this.dataSource.data = this.model.blacklist;

        this.isLoading = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  private initTemplateInfo() {
    this.bankService.getTemplates().subscribe(
      res => {
        this.model.templates = new Array<EmailTemplate>();

        res.templates.forEach((el: any) => {
          let templateTmp: EmailTemplate = new EmailTemplate();
          templateTmp.id = el.id;
          templateTmp.name = el.name;
          templateTmp.template = el.value;
          templateTmp.type = el.flow;

          this.model.templates.push(templateTmp);
        });

        this.isLoading = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  isSaveStatusDisabled(): boolean {
    return (this.model.status && this.statusSelected == "ATTIVO") || !(this.model.status || this.statusSelected == "ATTIVO");
  }

  saveStatus() {
    let action = this.statusSelected == "ATTIVO" ? "attivare" : "disattivare";
    let message = "Vuoi " + action + " la funzionalità di invio email?"
    this.openModal('email', message);
  }

  resetTemplates() {
    this.isLoading = true;
    this.templateSelected = new EmailTemplate();
    this.initTemplateInfo();
  }

  isSaveTemplateDisabled(): boolean {
    return this.templateSelected.id == 0;
  }

  saveTemplate() {
    this.isLoading = true;
    this.bankService.updateEmailTemplate(this.templateSelected.id, {template: this.templateSelected.template}).subscribe(
      res => {
        this.model.templates.forEach(el => {
          if(el.id == this.templateSelected.id) {
            el.template = this.templateSelected.template;
          }
        });

        this.showSuccess();
      },
      err => {
        this.showError(err);
      }
    );
  }

  showBlacklistForm(toShow: number) {
    this.showNewBlacklistElement = toShow == 1;
    this.newBlacklistElement = new EmailBlacklist();
  }

  isSaveBlacklistDisabled(): boolean {
    return !this.newBlacklistElement.match;
  }

  removeBlacklistElement(element: EmailBlacklist) {
    let message = "Vuoi eliminare il valore " + element.match + " dalla blacklist?";
    this.toDeleteBlacklistElement = element;
    this.openModal('blacklist', message);
  }

  saveBlacklist() {
    this.isLoading = true;
    this.model.blacklist.push(this.newBlacklistElement);

    this.bankService.updateEmailBlacklist({blacklist: this.model.blacklist}).subscribe(
      res => {
        this.showSuccess();
      },
      err => {
        this.showError(err);
      }
    );
  }

  private openModal(value: string, message: string) {
    switch (value) {
      case 'email': {
        const dialogRef = this.dialog.open(GenericConfirmationComponent, {
          data: {
            message: message
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.isLoading = true;
            this.model.status = this.statusSelected == "ATTIVO";
            this.bankService.updateEmailStatus({status: this.model.status}).subscribe(
              res => {
                this.showSuccess();
              },
              err => {
                this.showError(err);
              }
            );
          }
        });
      }
      break;

      case 'blacklist': {
        const dialogRef = this.dialog.open(GenericConfirmationComponent, {
          data: {
            message: message
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.isLoading = true;
            this.model.blacklist = this.model.blacklist.filter(el => {return el.match != this.toDeleteBlacklistElement.match});
            this.dataSource.data = this.model.blacklist;

            this.bankService.updateEmailBlacklist({blacklist: this.model.blacklist}).subscribe(
              res => {
                this.showSuccess();
              },
              err => {
                this.showError(err);
              }
            );
          }
        });
      }
      break;
    }
  }

  private resetFields() {
    this.templateSelected = new EmailTemplate();
    this.newBlacklistElement = new EmailBlacklist();
    this.toDeleteBlacklistElement = new EmailBlacklist();
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.BANK.path]);
        break;
      case 'here':
        this.router.navigate([ROUTES.BANK_EMAIL.path]);
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  private showSuccess() {
    this.resetFields();
    this.isLoading = false;
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
