import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { AngularMaterialModule, MyDateAdapter } from "./angular-material.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AgencyLandingComponent } from "./component/agency/agency-landing/agency-landing.component";
import { AgencyNewComponent } from "./component/agency/agency-new/agency-new.component";
import { AgencyUpdateComponent } from "./component/agency/agency-update/agency-update.component";
import { BankSettingsComponent } from "./component/bank/bank-landing/bank-settings.component";
import { AssociazioniFilialiComponent } from "./component/consultant/associazioni-filiali/associazioni-filiali.component";
import { EstrazioneReportVICComponent } from "./component/report/estrazione-report-vic/estrazione-report-vic.component";
import { ConsultantLandingComponent } from "./component/consultant/consultant-landing/consultant-landing.component";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { HomepageComponent } from "./landing/homepage/homepage.component";
import { MainPageComponent } from "./landing/main-page/main-page.component";
import { NotAllowedComponent } from "./landing/not-allowed/not-allowed.component";
import { PreLandingComponent } from "./landing/pre-landing/pre-landing.component";
import { ConfirmationUpdateAgenciesComponent } from "./modal/modal-agencies/confirmation-update-agencies/confirmation-update-agencies.component";
import { ModalCloseExtraordinaryComponent } from "./modal/modal-agencies/modal-close-extraordinary/modal-close-extraordinary.component";
import { ModalCloseFestivityComponent } from "./modal/modal-agencies/modal-close-festivity/modal-close-festivity.component";
import { ModalCreateServicesComponent } from "./modal/modal-agencies/modal-create-services/modal-create-services.component";
import { SettingsCashDesksComponent } from "./modal/modal-banks/settings-cash-desks/settings-cash-desks.component";
import { SettingsConsultantsComponent } from "./modal/modal-banks/settings-consultants/settings-consultants.component";
import { SettingsOpeningDaysComponent } from "./modal/modal-banks/settings-opening-days/settings-opening-days.component";
import { SettingsServiceComponent } from "./modal/modal-banks/settings-service/settings-service.component";
import { ConfirmationConsultantComponent } from "./modal/modal-consultant/confirmation-consultant/confirmation-consultant.component";
import { ModalCreateConsultantComponent } from "./modal/modal-consultant/modal-create-consultant/modal-create-consultant.component";
import { ModalUpdateConsultantComponent } from "./modal/modal-consultant/modal-update-consultant/modal-update-consultant.component";
import { ModalErrorComponent } from "./modal/modal-gestioneErrori/modal-error/modal-error.component";
import { ModalSuccessComponent } from "./modal/modal-gestioneErrori/modal-success/modal-success.component";
import { AuthGuard } from "./auth/auth.guard";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { BankEmailComponent } from "./component/bank/bank-email/bank-email.component";
import { GenericConfirmationComponent } from "./modal/generic-confirmation/generic-confirmation.component";
import { ReportLandingComponent } from './component/report/report-landing/report-landing.component';
import { EstrazioneReportComponent } from "./component/report/estrazione-report/estrazione-report.component";
import { IntegrationTestParentComponent } from "./component/integration-test/integration-test-parent/integration-test-parent.component";
import { IntegrationTestAgencyComponent } from "./component/integration-test/integration-test-agency/integration-test-agencycomponent";
import { IntegrationTestConsultantComponent } from "./component/integration-test/integration-test-consultant/integration-test-consultant.component";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MainPageComponent,
    BankSettingsComponent,
    SettingsOpeningDaysComponent,
    SettingsCashDesksComponent,
    SettingsConsultantsComponent,
    NotAllowedComponent,
    ModalSuccessComponent,
    ModalErrorComponent,
    GenericConfirmationComponent,
    ModalCloseFestivityComponent,
    ModalCloseExtraordinaryComponent,
    ModalCreateServicesComponent,
    SettingsServiceComponent,
    ConsultantLandingComponent,
    ModalCreateConsultantComponent,
    ModalUpdateConsultantComponent,
    ConfirmationConsultantComponent,
    AssociazioniFilialiComponent,
    EstrazioneReportComponent,
    EstrazioneReportVICComponent,
    PreLandingComponent,
    ConfirmationUpdateAgenciesComponent,
    AgencyLandingComponent,
    AgencyNewComponent,
    AgencyUpdateComponent,
    BankEmailComponent,
    ReportLandingComponent,
    IntegrationTestParentComponent,
    IntegrationTestAgencyComponent,
    IntegrationTestConsultantComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    AngularMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'it'},
    { provide: DateAdapter, useClass: MyDateAdapter }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
