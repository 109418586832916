<div class="container">
    <div class="breadcrumb">
        <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
        <span class="breadcrumb-current">Impostazioni Filiale</span>
    </div>

    <div class="menu-buttons">
        <div class="menu-elements" matRipple [matRippleCentered]="false"
            [matRippleDisabled]="false" [matRippleUnbounded]="false" (click)="goTo('new')">
            <div>
                <mat-icon svgIcon="update" aria-hidden="false" style="height: 150px; width: 150px;">
                </mat-icon>
            </div>
            <div style="margin-top: 15px">Crea Nuova Filiale</div>
        </div>
        <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" class="menu-elements" (click)="goTo('update')">
            <div>
                <mat-icon svgIcon="build" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
            </div>
            <div style="margin-top: 15px">Modifica Filiale Esistente</div>
        </div>
    </div>
</div>