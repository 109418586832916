import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Agency } from '../model/agency.model';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl + "/agencies";
  }

  getAgencies(): Observable<any> {
    let url = this.api_prefix;
    return this.http.get(url)
  }

  createSingleAgency(agency: Agency): Observable<any> {
    let url = this.api_prefix;
    return this.http.post(url, agency);
  }

  getSingleAgency(agencyId: number): Observable<any> {
    let url = this.api_prefix + `/${agencyId}`;
    return this.http.get(url);
  }

  updateSingleAgency(agencyId: number, agency: Agency): Observable<any> {
    let url = this.api_prefix + `/${agencyId}`;
    return this.http.put(url, agency);
  }

  getAllConfigurationsByAgencyId(agencyId: number): Observable<any> {
    let url = this.api_prefix + `/${agencyId}/configurations`;
    return this.http.get(url);
  }
  
  getSingleConfiguration(agencyId: any, configurationId: number): Observable<any> {
    let url = this.api_prefix + `/${agencyId}/configurations/${configurationId}`;
    return this.http.get(url);
  }

  deleteSingleConfiguration(agencyId: any, configurationId: any): Observable<any> {
    let url = this.api_prefix + `/${agencyId}/configurations/${configurationId}`;
    return this.http.delete(url);
  }
}
