export class Flow {
    flow!: string;
    jwt!: FlowCassa | FlowGestore
}

export class FlowCassa {
    clienteNome!: string;
    clienteCognome!: string;
    clienteEmail!: string;
    clienteIntestazione!: string;
    clienteCdg!: string;
    clienteIndirizzo!: string;
    filialeCodice!: string;
    canaleUtilizzo!: string;
    clienteRemoto!: boolean;
}

export class FlowGestore {
    clienteNome!: string;
    clienteCognome!: string;
    clienteEmail!: string;
    clienteIntestazione!: string;
    clienteCellulare!: string;
    clienteCdg!: string;
    clienteRemoto!: boolean;
    gestoreDescrizione!: string | string[];
    gestoreEmail!: string | string[];
    gestoreMatricola!: string | string[];
    filialeCodice!: string;
    opzioniPrenotazioneConsulente!: string;
    canaleUtilizzo!: string;
    argomento!: string;
}
