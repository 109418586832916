<div style="height: 670px;" *ngIf="isLoading">
    <div class="spinner-loading">
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="container">
    <div class="breadcrumb">
        <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
        <span class="breadcrumb-previous" (click)="goTo('landing')">Impostazioni Banca</span> >
        <span class="breadcrumb-current">Impostazioni Email</span>
    </div>

    <div>
        <form name="myForm">

            <mat-accordion>
                <mat-expansion-panel *ngIf="showEmail">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Gestione Invio Email
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="my-row">
                        <mat-form-field class="my-form-field-color my-row-element-2">
                            <mat-label>Status funzionalità di invio email:</mat-label>
                            <mat-select
                                [(ngModel)]="statusSelected" name="statusSelect">
                                <mat-option *ngFor="let item of ['ATTIVO', 'INATTIVO']" [value]="item">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="my-button-container">
                        <!--button class="my-button back" mat-raised-button (click)="resetTemplates()">
                            ANNULLA
                        </button-->
                        <button class="my-button confirm" mat-raised-button (click)="saveStatus()"
                            [disabled]="isSaveStatusDisabled()">
                            SALVA
                        </button>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Gestione Template
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="my-row">
                        <mat-form-field class="my-form-field-color my-row-element-2" appearance="fill">
                            <mat-label>Seleziona un template</mat-label>
                            <mat-select [(ngModel)]="templateSelected" name="templateSelect">
                                <mat-option
                                    *ngFor="let item of model.templates"
                                    [value]="item">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="templateSelected.id != 0">
                    
                        <div class="my-row following-row">
                            <mat-form-field class="my-row-element-1" appearance="fill">
                                <mat-label>Inserisci il template</mat-label>
                                <textarea [(ngModel)]="templateSelected.template" name="templateTextarea"
                                    matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="20"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="my-row following-row">
                            <div class="my-row-element-2">
                                <p class="legend">
                                    Placeholder Generali:<br>
                                </p>
                                <p *ngFor="let el of allowedPlaceholders.generic">
                                    {{el}}
                                </p>
                            </div>

                            <div class="my-row-element-2">
                                <ng-container *ngIf="templateSelected.type == 'CASSA'; else gestoreTemplate">
                                    <p class="legend">
                                        Placeholder Appuntamento in Cassa:<br>
                                    </p>
                                    <p *ngFor="let el of allowedPlaceholders.cassa">
                                        {{el}}
                                    </p>
                                </ng-container>
                                <ng-template #gestoreTemplate>
                                    <p class="legend">
                                        Placeholder Appuntamento con Gestore:<br>
                                    </p>
                                    <p *ngFor="let el of allowedPlaceholders.gestore">
                                        {{el}}
                                    </p>
                                </ng-template>
                            </div>
                        </div>

                        <div class="my-button-container">
                            <button class="my-button back" mat-raised-button (click)="resetTemplates()">
                                ANNULLA
                            </button>
                            <button class="my-button confirm" mat-raised-button (click)="saveTemplate()"
                                [disabled]="isSaveTemplateDisabled()">
                                SALVA
                            </button>
                        </div>

                    </ng-container>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Gestione Blacklist
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                
                    <div class="my-row">
                        <div class="my-row-element-1">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Indirizzi non ammessi </th>
                                    <td mat-cell *matCellDef="let element"> {{element.match}} </td>
                                </ng-container>
                
                                <!--ng-container matColumnDef="type">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipologia di Match </th>
                                                    <td mat-cell *matCellDef="let element"> {{element.isFullmatch}} </td>
                                                </ng-container-->
                
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef style="width: 20px;">
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="minusSmall" style="margin-left: 10px" (click)="removeBlacklistElement(element)">
                                            <mat-icon>remove</mat-icon>
                                        </div>
                                    </td>
                                </ng-container>
                
                                <tr mat-header-row *matHeaderRowDef="['name', 'action']"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['name', 'action'];"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="9999">
                                      Nessun valore presente
                                    </td>
                                </tr>
                
                            </table>
                
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                            </mat-paginator>
                
                            <div class="imgSmall">
                                <div class="plusSmall" (click)="showBlacklistForm(1)">
                                    <mat-icon>add</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="showNewBlacklistElement">
                        <div class="my-row following-row">
                            <div class="my-row-element-3">
                                <label for="newEl">Nuovo elemento in blacklist:</label>
                                <input class="my-input" type="text" name="newElement" [(ngModel)]="newBlacklistElement.match"
                                    autocomplete="off" />
                            </div>
                            <div class="my-row-element-3">
                                <!--label for="newElCheckbox">Match completo:</label>
                                <mat-checkbox [(ngModel)]="newBlacklistElement.isFullmatch" name="newElementCheckbox">
                                </mat-checkbox-->
                            </div>
                    
                            <div class="my-row-element-3"></div>
                        </div>
                    
                        <div class="my-button-container">
                            <button class="my-button back" mat-raised-button (click)="showBlacklistForm(0)">
                                ANNULLA
                            </button>
                            <button class="my-button confirm" mat-raised-button (click)="saveBlacklist()"
                                [disabled]="isSaveBlacklistDisabled()">
                                SALVA
                            </button>
                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>

        </form>
    </div>

</div>