import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FlowCassa } from 'src/app/model/integration-test.model';


@Component({
  selector: 'app-integration-test-agency',
  templateUrl: './integration-test-agency.component.html',
  styleUrls: ['./integration-test-agency.component.scss']
})
export class IntegrationTestAgencyComponent implements OnInit {

  cassa: FlowCassa = new FlowCassa();

  cassaForm: FormGroup = {} as FormGroup;

  @Output('cassaSave') cassaSave = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    this.generateForm()
  }

  generateForm() {
    this.cassaForm = new FormGroup({
      clienteNome: new FormControl(''),
      clienteCognome: new FormControl(''),
      clienteEmail: new FormControl('', Validators.email),
      clienteIntestazione: new FormControl(''),
      clienteCdg: new FormControl(''),
      clienteIndirizzo: new FormControl(''),
      filialeCodice: new FormControl('', [Validators.pattern('[0-9]+'), Validators.maxLength(5), Validators.minLength(5)]),
      canaleUtilizzo: new FormControl(''),
      clienteRemoto: new FormControl(''),
    })
  }

  get cassaFormGet() { return this.cassaForm.controls }

  inserimento() {
   if(this.cassaForm.get('clienteRemoto')?.value != "") {
        this.cassa.clienteRemoto = this.cassaFormGet['clienteRemoto'].value == "true";
   }
    this.cassa.clienteNome = this.cassaFormGet['clienteNome'].value
    this.cassa.clienteCognome = this.cassaFormGet['clienteCognome'].value
    this.cassa.clienteEmail = this.cassaFormGet['clienteEmail'].value
    this.cassa.clienteIntestazione = this.cassaFormGet['clienteIntestazione'].value
    this.cassa.clienteCdg = this.cassaFormGet['clienteCdg'].value
    this.cassa.clienteIndirizzo = this.cassaFormGet['clienteIndirizzo'].value
    this.cassa.filialeCodice = this.cassaFormGet['filialeCodice'].value
    this.cassa.canaleUtilizzo =  this.cassaFormGet['canaleUtilizzo'].value.toUpperCase()
  }

  getErrorsPattern(field: string) {
    if (this.cassaForm.get(field)?.hasError('pattern')
      && this.cassaForm.get(field)?.touched
      && this.cassaForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  getErrorsLength(field: string) {
    if (this.cassaForm.get(field)?.hasError('minLength')
    || this.cassaForm.get(field)?.hasError('maxLength')
      && this.cassaForm.get(field)?.touched
      && this.cassaForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  getErrorsEmail(field: string) {
    if (this.cassaForm.get(field)?.hasError('email')
      && this.cassaForm.get(field)?.touched
      && this.cassaForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  save() {
    this.inserimento()
    console.log(this.cassa)
    this.cassaSave.emit(this.cassa)
  }

}
