import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Service } from 'src/app/model/agency.model';

interface DialogData {
  services: Array<Service>;
  newService: Service;
}

@Component({
  selector: 'app-modal-create-services',
  templateUrl: './modal-create-services.component.html',
  styleUrls: ['./modal-create-services.component.css']
})
export class ModalCreateServicesComponent implements OnInit {

  errorValueService!: boolean;
  errorNameService!: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: DialogData
  ) {}

  ngOnInit(): void {console.log(this.inputData)}

  close() {
    this.dialogRef.close();
  }
}
