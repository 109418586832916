import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyError } from 'src/app/constants/error';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {

  hasToggle: boolean = false;

  constructor(public dialogRef: MatDialogRef<ModalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public errorDetails: MyError) { }

  ngOnInit(): void {
    if(this.errorDetails.customDetails) this.hasToggle = true;
  }

  close() {
    this.dialogRef.close();
  }

}
