import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FlowGestore } from 'src/app/model/integration-test.model';


@Component({
  selector: 'app-integration-test-consultant',
  templateUrl: './integration-test-consultant.component.html',
  styleUrls: ['./integration-test-consultant.component.scss']
})
export class IntegrationTestConsultantComponent implements OnInit {

  gestore: FlowGestore = new FlowGestore();
  gestoreForm: FormGroup = {} as FormGroup;
  opzioni = '';

  @Output() gestoreFlow = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    this.gestoreForm = new FormGroup({
      clienteNome: new FormControl(''),
      clienteCognome: new FormControl(''),
      clienteEmail: new FormControl('', Validators.email),
      clienteIntestazione: new FormControl(''),
      clienteCellulare: new FormControl(''),
      clienteIstituto: new FormControl(''),
      clienteCdg: new FormControl(''),
      clienteRemoto: new FormControl(''),
      gestoreDescrizione: new FormControl(''),
      gestoreEmail: new FormControl(''),
      gestoreMatricola: new FormControl(''),
      filialeCodice: new FormControl('', [Validators.pattern('[0-9]+'), Validators.maxLength(5), Validators.minLength(5)]),
      opzioniPrenotazioneConsulente: new FormControl(''),
      canaleUtilizzo: new FormControl('', [Validators.maxLength(3), Validators.minLength(3)]),
      argomento: new FormControl(''),
    })
  }

  get gestoreFormGet() { return this.gestoreForm.controls }

  fillOutputObject() {
    this.gestore.clienteNome = this.gestoreFormGet['clienteNome'].value
    this.gestore.clienteCognome = this.gestoreFormGet['clienteCognome'].value
    this.gestore.clienteEmail = this.gestoreFormGet['clienteEmail'].value
    this.gestore.clienteIntestazione = this.gestoreFormGet['clienteIntestazione'].value
    this.gestore.clienteCdg = this.gestoreFormGet['clienteCdg'].value
    this.gestore.clienteCellulare = this.gestoreFormGet['clienteCellulare'].value
    this.gestore.filialeCodice = this.gestoreFormGet['filialeCodice'].value
    this.gestore.gestoreDescrizione = this.gestoreFormGet['gestoreDescrizione'].value
    this.gestore.gestoreEmail = this.gestoreFormGet['gestoreEmail'].value
    this.gestore.gestoreMatricola = this.gestoreFormGet['gestoreMatricola'].value
    this.gestore.opzioniPrenotazioneConsulente = this.opzioni === 'Filiale,Meet' ? this.opzioni = 'Meet,Filiale' : this.opzioni
    this.gestore.canaleUtilizzo = this.gestoreFormGet['canaleUtilizzo'].value.toUpperCase()
  }

  getErrorsPattern(field: string) {
    if (this.gestoreForm.get(field)?.hasError('pattern')
      && this.gestoreForm.get(field)?.touched
      && this.gestoreForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  getErrorsLength(field: string) {
    if (this.gestoreForm.get(field)?.hasError('minlength')
      || this.gestoreForm.get(field)?.hasError('maxlength')
      && this.gestoreForm.get(field)?.touched
      && this.gestoreForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  getErrorsEmail(field: string) {
    if (this.gestoreForm.get(field)?.hasError('email')
      && this.gestoreForm.get(field)?.touched
      && this.gestoreForm.get(field)?.dirty) {
      return true;
    }
    return false
  }

  getErrorsRequired(field: string) {
    if (this.gestoreForm.get(field)?.hasError('required')) {
      return true;
    }
    return false
  }

  save() {

    this.fillOutputObject();

    if(this.gestoreForm.get('clienteRemoto')?.value != "") {
      this.gestore.clienteRemoto = this.gestoreFormGet['clienteRemoto'].value == "true";
    }
    this.gestore.argomento = this.gestoreFormGet['argomento'].value;

    if(
      this.gestoreForm.get('clienteRemoto')?.value === "true"
      || (
        this.gestoreForm.get('gestoreDescrizione')?.value.includes(',')
        && this.gestoreForm.get('gestoreEmail')?.value.includes(',')
        && this.gestoreForm.get('gestoreMatricola')?.value.includes(',')
      )
    ){
        var descrizione = this.gestoreForm.get('gestoreDescrizione')?.value.split(',')
        var email = this.gestoreForm.get('gestoreEmail')?.value.split(',')
        var matricola = this.gestoreForm.get('gestoreMatricola')?.value.split(',')

        this.gestore.gestoreDescrizione = descrizione
        this.gestore.gestoreEmail = email
        this.gestore.gestoreMatricola = matricola

        this.gestore.opzioniPrenotazioneConsulente = 'Meet';
    }

    this.gestoreFlow.emit(this.gestore);
  }

  changeCheckbox(field: string, check: any) {

    if (check._checked && check.id === 'meet') {
      if(this.opzioni.length === 0) {
        this.opzioni += field;
      } else {
        this.opzioni += ',' + field;
      }
    } else if (check._checked && check.id === 'filiale') {
      if(this.opzioni.length === 0) {
        this.opzioni += field;
      } else {
        this.opzioni += ',' + field;
      }
    } else if (!check._checked && check.id === 'meet') {
      if(this.opzioni.includes(',')) {
        var repl = this.opzioni.replace( ',' + field, '');
        this.opzioni = repl;
      } else {
        var repl = this.opzioni.replace(field, '');
        this.opzioni = repl;
      }
    } else if (!check._checked && check.id === 'filiale') {
      if(this.opzioni.includes(',')) {
        var repl = this.opzioni.replace( ',' + field, '');
        this.opzioni = repl;
      } else {
        var repl = this.opzioni.replace(field, '');
        this.opzioni = repl;
      }
    }

    if(this.opzioni.length === 0) {
      this.gestoreForm.get('opzioniPrenotazioneConsulente')?.reset();
    }
  }

  get placeholderEmail(): string {
    if(this.gestoreForm.get('clienteRemoto')?.value === 'true') {
      return 'email1@credem.it, email2@credem.it'
    }

    return 'email@credem.it';
  }

  get placeholderMatricola(): string {
    if(this.gestoreForm.get('clienteRemoto')?.value === 'true') {
      return 'UT12345, UT67890'
    }

    return 'UT12345';
  }

  get placeholderDescrizione(): string {
    if(this.gestoreForm.get('clienteRemoto')?.value === 'true') {
      return 'Mario Rossi, Anna Verdi'
    }

    return 'Mario Rossi';
  }
}
