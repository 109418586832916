<div class="modal">
  <div>
    <label>Aggiungi nuovi giorni di chiusura:</label>
  </div>
  <div class="textModal">
    <label for="slot" class="mini-label">
      Fascia oraria di chiusura:
    </label>
    <select [(ngModel)]="inputData.type" name="slot" class="my-select" id="slot">
      <option value="ALL_DAY">Tutto il giorno</option>
      <option value="M">Mattina</option>
      <option value="P">Pomeriggio</option>
    </select>
  </div>
  <div class="my-row">
    <div class="my-row-element-2">
      <mat-form-field>
        <mat-label>Inserisci data inizio:</mat-label>
        <input matInput [matDatepicker]="picker" style="border-bottom: none;"
          [(ngModel)]="inputData.start_date" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="my-row-element-2">
      <mat-form-field>
        <mat-label>Inserisci data fine:</mat-label>
        <input matInput [matDatepicker]="picker1" style="border-bottom: none;"
          [(ngModel)]="inputData.end_date" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="my-button-container">
    <button class="my-button back" mat-raised-button (click)="close()">
      ANNULLA
    </button>
    <button class="my-button confirm" mat-raised-button (click)="saveData()" [disabled]="false">
      CONFERMA
    </button>
  </div>
</div>