<div class="container">
  <div mat-dialog-title class="title">Gestione servizi</div>
  <mat-dialog-content class="mat-typography">
    <div class="inner-section">
      <div class="inner-title">Servizi disponibili:</div>
      <div style="max-height: 300px; overflow-y: scroll">
        <div
          *ngFor="let item of services; let i = index"
          class="time-table-flex"
        >
          <label for="services">{{ item.name }}</label>
          <select
            name="services"
            id="services"
            (change)="setServices($event, item)"
            [(ngModel)]="item.active"
          >
            <option [value]="1">ATTIVO</option>
            <option [value]="0">DISATTIVO</option>
          </select>
          <div class="delete">
            <div
              class="minusSmall"
              style="color: white"
              matRipple
              [matRippleCentered]="false"
              [matRippleDisabled]="false"
              [matRippleUnbounded]="false"
              [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
              (click)="removeService(item, i)"
            >
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin: 20px 0px" />
      <div class="inner-title">Aggiungi nuovi servizi:</div>

      <div style="margin-top: 20px">
        <label for="newService">Nuovo servizio</label>
        <input
          [(ngModel)]="nameService"
          type="text"
          name="newService"
          id="newService"
          autocomplete="off"
        />
      </div>

      <div class="imgSmall" style="margin-top: 20px">
        <div
          class="plusSmall"
          matRipple
          [matRippleCentered]="false"
          [matRippleDisabled]="false"
          [matRippleUnbounded]="false"
          [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
          (click)="addService()"
          style="color: white"
        >
          <mat-icon>add</mat-icon>
        </div>
      </div>
      <div>
        <div id="error" *ngFor="let error of errors">
          <p class="error" id="errorName">Errore:</p>
          <br /><span style="font-size: 16px; color: #20623b; display: flex">{{
            error
          }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="margin-bottom: -10px" id="btn">
    <div style="display: flex">
      <div
        matRipple
        [matRippleCentered]="false"
        [matRippleDisabled]="false"
        [matRippleUnbounded]="false"
        class="button-cancel"
        (click)="close()"
      >
        ANNULLA
      </div>
      <div
        matRipple
        [matRippleCentered]="false"
        [matRippleDisabled]="false"
        [matRippleUnbounded]="false"
        [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
        class="button-save"
        (click)="save()"
      >
        SALVA
      </div>
    </div>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>
</div>
