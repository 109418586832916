import { Component, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.css']
})
export class ModalSuccessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalSuccessComponent>) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

}
