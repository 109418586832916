<div class="container">
    <div class="breadcrumb">
        <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
        <span class="breadcrumb-current">Gestione Report</span>
    </div>

    <div class="menu-buttons">
        <div *ngIf="viewReport" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" class="menu-elements" (click)="goTo('report')">
            <div>
                <mat-icon svgIcon="report" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
            </div>
            <div style="margin-top: 15px">Estrazione Report</div>
        </div>

        <div *ngIf="viewReportVIC" class="menu-elements" matRipple [matRippleCentered]="false"
            [matRippleDisabled]="false" [matRippleUnbounded]="false" (click)="goTo('report-vic')">
            <div>
                <mat-icon svgIcon="reportVIC" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
            </div>
            <div style="margin-top: 15px">Estrazione Report - VIC</div>
        </div>
    </div>
</div>