import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-consultant',
  templateUrl: './confirmation-consultant.component.html',
  styleUrls: ['./confirmation-consultant.component.css']
})
export class ConfirmationConsultantComponent implements OnInit {

  name!: string;
  surname!: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationConsultantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data)
    this.name = this.data.consultant_name;
    this.surname = this.data. consultant_surname;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let request = this.data;
    this.dialogRef.close(request);
  }

}
