import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl;
  }

  getVICReportData(start: string, end: string): Observable<any> {
    let url = this.api_prefix + "/reports/consultant";
    let params = new HttpParams();
    params = params.set('type', "VIC");
    params = params.set('start', start);
    params = params.set('end', end);

    return this.http.get(url, { params: params });
  }

  getConsultantReportData(start: string, end: string): Observable<any> {
    let url = this.api_prefix + "/reports/consultant";
    let params = new HttpParams();
    params = params.set('start', start);
    params = params.set('end', end);

    return this.http.get(url, { params: params });
  }

  getAgencyReportData(start: string, end: string): Observable<any> {
    let url = this.api_prefix + "/reports/agency";
    let params = new HttpParams();
    params = params.set('start', start);
    params = params.set('end', end);

    return this.http.get(url, { params: params });
  }
}
