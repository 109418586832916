import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MyErrorHandler } from 'src/app/constants/error';
import { ConfirmationUpdateAgenciesComponent } from 'src/app/modal/modal-agencies/confirmation-update-agencies/confirmation-update-agencies.component';
import { ModalCloseExtraordinaryComponent } from 'src/app/modal/modal-agencies/modal-close-extraordinary/modal-close-extraordinary.component';
import { ModalCloseFestivityComponent } from 'src/app/modal/modal-agencies/modal-close-festivity/modal-close-festivity.component';
import { ModalCreateServicesComponent } from 'src/app/modal/modal-agencies/modal-create-services/modal-create-services.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { Agency, Closings, OpeningDays, OpeningHours, Service, SpecificHoliday, TimeSlots } from 'src/app/model/agency.model';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { AgenciesService } from 'src/app/services/agencies.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-agency-new',
  templateUrl: './agency-new.component.html',
  styleUrls: ['./agency-new.component.scss']
})
export class AgencyNewComponent implements OnInit {

  spinnerView: boolean = true;
  private roles: ROLES[] = [];

  generalSettings: any;

  selectedAgency: Agency = new Agency();

  openings: Array<TimeSlots> = new Array<TimeSlots>();

  status = [{value: "DISABLED", label: "INATTIVA"}];

  canSeeCalendar: boolean = false;
  isClosedMorning: boolean = false;
  isClosedAfternoon: boolean = false;

  opening_days: OpeningDays = new OpeningDays();

  dataDecorrenza: Date = new Date();

  maps_options: Options = new Options({
    fields: [
      "formatted_address",
      "geometry",
      "url",
      "address_components"
    ],
    componentRestrictions: {
      country: "it"
    },
    strictBounds: true,
    types: []
  });

  constructor(
    private agencyService: AgenciesService,
    private userService: UserService,
    private router: Router,
    private datePipe : DatePipe,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.openings.push(new TimeSlots());
    this.openings.push(new TimeSlots());

    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      if(this.roles.includes(ROLES.ADMIN)) {
        this.canSeeCalendar = true;
        this.status.push({value: "OPERATIVE", label: "OPERATIVA"});
      }
      this.loadAgenciesList();
    }
  }

  handleAddressChange(address: any) {
    this.selectedAgency.CAP = "";
    this.selectedAgency.city_province = "";
    this.selectedAgency.city_name = "";
    this.selectedAgency.address = address.formatted_address;


    this.selectedAgency.latitude = address.geometry.location.lat();
    this.selectedAgency.longitude = address.geometry.location.lng();

    for(let i  = 0; i < address.address_components.length; i++){
      if (address.address_components[i].types.indexOf("administrative_area_level_3") !== -1){
          this.selectedAgency.city_name = address.address_components[i].long_name;
      }
      else if (address.address_components[i].types.indexOf("administrative_area_level_2") !== -1){
          this.selectedAgency.city_province = address.address_components[i].short_name;
      }
      else if (address.address_components[i].types.indexOf("postal_code") !== -1){
          this.selectedAgency.CAP = address.address_components[i].long_name;
      }
    }
  }


  private loadAgenciesList() {
    this.agencyService.getAgencies().subscribe(
      data => {
        this.generalSettings = data.general_settings;
        this.parseGeneralOpenings();
        this.spinnerView = false;
      },
      err => {
        console.error(err);
      }
    )
  }

  parseGeneralOpenings() {
    const chars = [...this.generalSettings.openings];

    chars.forEach(
      (el, index) => {
        this.opening_days.morning[index] = el == '0';
        this.opening_days.afternoon[index] = el == '0';
      }
    );
  }

  resetDate() {
    this.dataDecorrenza = new Date();
  }

  openModal(value: string) {
    switch (value) {
      case 'service': {
        let newService: Service = new Service();

        const dialogRef = this.dialog.open(ModalCreateServicesComponent, {
          data: {
            services: this.generalSettings.services,
            newService: newService
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.agency_services = this.selectedAgency.agency_services.filter(el => el.id_service != result.id_service);
            this.selectedAgency.agency_services.push(result);
          }
        });
      }
      break;

      case 'specific_holidays': {
        let newFestivity: SpecificHoliday = new SpecificHoliday();
        const dialogRef = this.dialog.open(ModalCloseFestivityComponent, {
          data: newFestivity
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.specific_holidays = this.selectedAgency.specific_holidays.filter(el => el.date != result.date);
            this.selectedAgency.specific_holidays.push(result);
          }
        });
      }
      break;

      case 'closing': {
        let newClosing: Closings = new Closings();
        const dialogRef = this.dialog.open(ModalCloseExtraordinaryComponent, {
          data: newClosing
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.closings.push(result);
          }
        });
      }
      break;

      case 'save': {
        const dialogRef = this.dialog.open(ConfirmationUpdateAgenciesComponent);

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.dataDecorrenza = result;
            this.saveButton();
          }
        });
      }
      break;
    }

  }

  typeAgency(value: string) {
    return value == "ALL_DAY"? "TOTALE" : value == "M"? "MATTINA" : "POMERIGGIO";
  }

  removeService(id: number | undefined) {
    if(id)
      this.selectedAgency.agency_services = this.selectedAgency.agency_services.filter(el => el.id_service != id);
  }

  removeFestivity(date: string | null) {
    if(date)
      this.selectedAgency.specific_holidays = this.selectedAgency.specific_holidays.filter(el => el.date != date);
  }

  removeClosingDays(value: Closings) {
    if(value.start_date && value.end_date) {
      this.selectedAgency.closings = this.selectedAgency.closings.filter(el => (
        el.start_date != value.start_date && el.end_date != value.end_date && el.type != value.type
      ));
    }
  }

  isProvinceInvalid(): boolean {
    return !(this.selectedAgency.city_province && this.selectedAgency.city_province.length == 2 && /[A-Z]/.test(this.selectedAgency.city_province));
  }

  isCAPInvalid(): boolean {
    return !(this.selectedAgency.CAP && this.selectedAgency.CAP.length == 5 && /^\d+$/.test(this.selectedAgency.CAP));
  }

  isAgencyCodeInvalid(): boolean {
    return !(this.selectedAgency.CODE && this.selectedAgency.CODE.length <= 5 && /^\d+$/.test(this.selectedAgency.CODE));
  }

  isSaveDisabled(): boolean {
    return (
      !this.selectedAgency.name ||
      !this.selectedAgency.city_name ||
      this.isProvinceInvalid() ||
      this.isCAPInvalid() ||
      !this.selectedAgency.address ||
      this.isAgencyCodeInvalid() ||
      (!this.isClosedMorning && this.openings[0].isInvalid()) ||
      (!this.isClosedAfternoon && this.openings[1].isInvalid()) ||
      (this.selectedAgency.future_settings.status == "OPERATIVE" && !this.selectedAgency.google_calendar_account) ||
      !this.selectedAgency.future_settings.status ||
      !this.dataDecorrenza
    );
  }

  private saveButton() {
    this.spinnerView = true;

    this.selectedAgency.future_settings.opening_hours = new Array<OpeningHours>();

    if(!this.isClosedMorning) {
      let oh = new OpeningHours();
      oh.buildFromTimeSlot(this.openings[0]);
      this.selectedAgency.future_settings.opening_hours.push(oh);
    }

    if(!this.isClosedAfternoon) {
      let oh = new OpeningHours();
      oh.buildFromTimeSlot(this.openings[1]);
      this.selectedAgency.future_settings.opening_hours.push(oh);
    }

    let days = OpeningDays.buildOpeningDaysFromOpeningDays(this.opening_days);

    this.selectedAgency.future_settings.opening_morning = days[0];
    this.selectedAgency.future_settings.opening_afternoon = days[1];

    let date = this.datePipe.transform(this.dataDecorrenza, 'yyyy-MM-dd');
    if(date) this.selectedAgency.future_settings.start_operative = date;

    this.agencyService.createSingleAgency(this.selectedAgency).subscribe(
      data => {
        this.showSuccess();

        this.spinnerView = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.AGENCY.path]);
        break;
      case 'here':
        this.router.navigate([ROUTES.AGENCY_NEW.path]);
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('here');
    });
  }

  private showSuccess() {
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

}
