import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { from } from 'rxjs'

import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.myHandle(request, next))
    }

    async myHandle(request: HttpRequest<any>, next: HttpHandler) {
        const token: string | null = await this.userService.getCurrentUserToken();

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        } else {
            console.error("Auth token is missing")
        }

        return next.handle(request).toPromise();
    }
}