<div class="modalError">
    <div>
      <h4 class="titleModal">Errore</h4>
    </div>
    <mat-expansion-panel *ngIf="errorDetails">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{errorDetails.customText}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{errorDetails.customDetails}}</p>
    </mat-expansion-panel>

    <div class="my-button-container">
      <button class="my-button back" mat-raised-button (click)="close()">CHIUDI</button>
    </div>
</div>