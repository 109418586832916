import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-gestore',
  templateUrl: './consultant-landing.component.html',
  styleUrls: ['./consultant-landing.component.scss']
})
export class ConsultantLandingComponent implements OnInit {

  roles!: ROLES[];
  viewConsultant!: boolean;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      this.getRole();
    }
  }

  private getRole() {
    if (this.roles.includes(ROLES.ADMIN)) {
      this.viewConsultant = true;
    } else {
      if (this.roles.includes(ROLES.BANCA)) {
        this.viewConsultant = true;
      }
    }
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'consultant':
        this.router.navigate([ROUTES.CONSULTANT_AGENCY.path]);
        break;
    }
  }

}
