<div class="modalSuccess">
    <div class="headerModal">
      <h4 class="titleModalConsultant">Gestore creato con successo</h4>
    </div>
    <div
      class="btnModal"
      style="
        justify-content: center;
        margin-top: 50px;
        text-align: center;
        margin-right: 0px;
      "
    >
      <button class="btnConsultant" (click)="close()">CHIUDI</button>
    </div>
</div>