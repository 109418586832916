<div class="spinner-loading" style="flex-direction: column;" *ngIf="loading">
    <div>
        <span class="spinner-double-dot-stick"></span>
    </div>

    <div *ngIf="allowPopupMessage" style="padding: 25px; font-size: 20px; color: #20623b;">
        <p style="text-align: center;">Consenti l'apertura dei popup, quindi ricarica la pagina.</p>
    </div>
</div>

<div *ngIf="!loading">
    <div class="header">
        <div class="title">
            <h3>Pannello di Amministrazione - Prenota Appuntamento</h3>
        </div>
        <div class="login">
            <p>{{user.user_email}}</p>
            <div class="dropdown-content">
                <p (click)="logout()">Esci</p>
            </div>
        </div>
    </div>
    
    <div>
        <router-outlet></router-outlet>
    </div>

    <hr class="line">
    <div class="footer">
        <img src="./../assets/logo_Credem.jpg" alt="logoCredem" width="250px" height="150px">
    </div>
</div>