<div style="height: 670px;" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div class="container" id="sectionTop" *ngIf="!spinnerView">

  <div class="breadcrumb">
    <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
    <span class="breadcrumb-current">Test di Integrazione</span>
  </div>

  <div>
    <mat-form-field appearance="fill" class="my-row-element-3">
      <mat-label>Seleziona flusso</mat-label>
      <mat-select #tipo (selectionChange)="selection()" [(ngModel)]="selected">
        <mat-option value="cassa" selected>Appuntamento in Cassa</mat-option>
        <mat-option value="gestore">Appuntamento con Gestore</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="showCassa" class="childForm">
    <app-integration-test-agency (cassaSave)="save($event)"></app-integration-test-agency>
  </div>

  <div *ngIf="showGestore" class="childForm">
    <app-integration-test-consultant (gestoreFlow)="save($event)"></app-integration-test-consultant>
  </div>

</div>
