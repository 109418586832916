export const environment = {
  firebase: {
    projectId: 'goreply-credem-wrt-test',
    appId: '1:958170381807:web:ff0e3459f308c3885f4a1a',
    storageBucket: 'goreply-credem-wrt-test.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyC4h4fffpZUSdmKn7OJeHn7hemWJLGbo9Q',
    authDomain: 'goreply-credem-wrt-test.firebaseapp.com',
    messagingSenderId: '958170381807',
  },
  provider: "oidc.goreply-credem-wrt-test",
  providerLogout: "https://idp-coll.credem.it/pkmslogout",
  production: true,
  isProd: false,
  backEndUrl: "https://be-admin-panel-dot-goreply-credem-wrt-test.ew.r.appspot.com",
  mapsAPIKey: "AIzaSyAk2Oheo1TnT3VXH0l3GV0Ly2H7XGWD6ms"
};
