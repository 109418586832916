import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-update-agencies',
  templateUrl: './confirmation-update-agencies.component.html',
  styleUrls: ['./confirmation-update-agencies.component.scss'],
})
export class ConfirmationUpdateAgenciesComponent implements OnInit {

  minDate: Date = new Date();
  dataDecorrenza: Date | undefined;
  isDecorrenzaImmediata: boolean = true;
  isDataDecorrenzaEsistente: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationUpdateAgenciesComponent>,
    private datePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public inputData: Array<string>
  ) { }

  ngOnInit(): void {
  }

  resetDate() {
    this.dataDecorrenza = undefined;
    this.isDataDecorrenzaEsistente = false;
  }

  checkDecorrenza() {
    let date = this.datePipe.transform(this.dataDecorrenza, 'dd-MM-yyyy');
    let found = this.inputData.findIndex(el => {
      return el == date;
    }) != -1;

    this.isDataDecorrenzaEsistente = found;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if(!this.dataDecorrenza) this.dataDecorrenza = new Date();
    this.dialogRef.close(this.dataDecorrenza);
  }


}
