<div class="section" id="section">

    <div class="big-button" *ngIf="viewBranches" (click)="goToSettings(agency_url)">
        <!--div class="img-button">
            <img src="./../../assets/filiale.png" alt="iconFiliale" width="250px" height="250px">
        </div-->
        <div class="img-button">
            <mat-icon svgIcon="agency-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Impostazioni Filiale</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewBank" (click)="goToSettings(bank_url)">
        <!--div class="img-button">
            <img src="./../../assets/banca.png" alt="iconBanca" width="250px" height="250px">
        </div-->
        <div class="img-button">
            <mat-icon svgIcon="bank-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Impostazioni Banca</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewGestore" (click)="goToSettings(consultant_url)">
        <!--div class="img-button">
            <img src="./../../assets/gestore.png" alt="iconGestore" width="250px" height="250px">
        </div-->
        <div class="img-button">
            <mat-icon svgIcon="consultant-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Impostazioni Gestori</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewReport" (click)="goToSettings(report_url)">
        <div class="img-button">
            <mat-icon svgIcon="report-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Gestione Report</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewIntegrationTest" (click)="goToSettings(tests_url)">
      <div class="img-button">
          <mat-icon svgIcon="integration-test-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
      </div>
      <div class="label-box">
          <h3>Test di Integrazione</h3>
      </div>
  </div>

</div>
