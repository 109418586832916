import { Component, OnInit } from '@angular/core';
import { Role, ROLES, User } from '../../model/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
})
export class MainPageComponent implements OnInit {
  roles!: ROLES[];
  viewBank!: boolean;
  viewBranches!: boolean;
  viewGestore!: boolean;
  viewReport!: boolean;
  viewIntegrationTest!:boolean;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      this.getRole();
    }
  }

  private getRole() {
    if (this.roles.includes(ROLES.ADMIN)) {
      this.viewBranches = true;
      this.viewBank = true;
      this.viewGestore = true;
      this.viewReport = true;
      this.viewIntegrationTest = true;
    } else {
      if (this.roles.includes(ROLES.BANCA)) {
        this.setStyle('space-around');
        this.viewBank = true;
        this.viewGestore = true;
      }

      if (this.roles.includes(ROLES.FILIALE)){
        this.setStyle('center');
        this.viewBranches = true;
      }

      if (this.roles.includes(ROLES.CALLCENTER_ADMIN)){
        this.setStyle('center');
        this.viewReport = true;
      }

      if (this.roles.includes(ROLES.REPORT_CASSA_GESTORE)){
        this.setStyle('center');
        this.viewReport = true;
      }
    }
  }

  private setStyle(style: string) {
    const section = document.getElementById('section');

    if (section != null) {
      section.style.justifyContent = style;
    }
  }
}
