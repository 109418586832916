<div class="modal">
    <div>
        <label>Aggiungi un servizio alla filiale:</label>
    </div>
    <div class="textModal">
        <label for="services" class="mini-label">
            Servizi disponibili:
        </label>
        <select [(ngModel)]="inputData.newService" name="services" class="my-select" id="services">
            <option *ngFor="let item of inputData.services" [ngValue]="item">
                {{item.name}}
            </option>
        </select>
    </div>
    <div class="textModal">
        <label for="service" class="mini-label">
            Inserisci la quantità per il servizio selezionato:
        </label>
        <input type="number" id="service" name="service" min="0" class="my-input"
            [(ngModel)]="inputData.newService.value" autocomplete="off">
    </div>
    <div class="my-button-container">
        <button class="my-button back" mat-raised-button (click)="close()">
            ANNULLA
        </button>
        <button class="my-button confirm" mat-raised-button
            [mat-dialog-close]="inputData.newService" [disabled]="!inputData.newService.value || !inputData.newService.name">
            CONFERMA
        </button>
    </div>
</div>