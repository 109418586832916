<form [formGroup]="gestoreForm">

  <h3>Dati Cliente:</h3>
  <div class="my-row indent">

    <div class="my-row-element-3">
      <label for="clienteName">Nome</label>
      <br />
      <input formControlName="clienteNome" class="my-input" type="text" id="clienteName" name="clienteName" />
    </div>

    <div class="my-row-element-3">
      <label for="clienteCognome">Cognome</label>
      <br />
      <input class="my-input" type="text" id="clienteCognome" name="clienteCognome" formControlName="clienteCognome" />
    </div>

    <div class="my-row-element-3">
      <label for="clienteIntestazione">Intestazione</label>
      <br />
      <input class="my-input" type="text" id="clienteIntestazione" name="clienteIntestazione"
        formControlName="clienteIntestazione" />
    </div>

  </div>

  <div class="my-row following-row indent">
    <div class="my-row-element-3">
      <label for="clienteEmail">Email</label>
      <br />
      <input class="my-input" type="email" id="clienteEmail" name="clienteEmail" formControlName="clienteEmail" />
      <div *ngIf="getErrorsEmail('clienteEmail')" class="errorInput">
        <p>Email non valida</p>
      </div>
    </div>

    <div class="my-row-element-3">
      <label for="clienteCellulare">Cellulare</label>
      <br />
      <input class="my-input" type="text" id="clienteCellulare" name="clienteCellulare"
        formControlName="clienteCellulare" maxlength="13"/>
    </div>

    <div class="my-row-element-3">
      <label for="clienteCdg">CDG</label>
      <br />
      <input class="my-input" type="text" id="clienteCdg" name="clienteCdg" formControlName="clienteCdg" />

    </div>

  </div>

  <div class="my-row following-row indent">
    <div class="my-row-element-3">
      <label for="clienteRemoto">Cliente Remoto</label>
      <mat-radio-group aria-label="Cliente Remoto" formControlName="clienteRemoto">
        <mat-radio-button value=true style="margin-right: 10px;">Si</mat-radio-button>
        <mat-radio-button value=false>No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h3>Dati Generali:</h3>
  <div class="my-row indent">

    <div class="my-row-element-3">
      <label for="filialeCodice">Codice Filiale</label>
      <br />
      <input class="my-input" type="text" id="filialeCodice" name="filialeCodice" formControlName="filialeCodice" />
      <div class="errorInput">
        <mat-error *ngIf="getErrorsLength('filialeCodice')">Il campo Codice Filiale deve contenere esattamente 5 numeri
        </mat-error>
        <p *ngIf="getErrorsPattern('filialeCodice')">Il campo Codice Filiale deve essere composto da numeri
        </p>
      </div>
    </div>
    <div class="my-row-element-3">
      <label for="canaleUtilizzo">Canale Utilizzo</label>
      <br />
      <input class="my-input" type="text" id="canaleUtilizzo" name="canaleUtilizzo" formControlName="canaleUtilizzo"
        onkeyup="this.value = this.value.toUpperCase();" />
      <div class="errorInput">
        <mat-error *ngIf="getErrorsLength('canaleUtilizzo')">Il campo Canale Utilizzo deve contenere 3 caratteri
        </mat-error>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h3>Dati Gestore:</h3>
  <div class="my-row indent">
    <div class="my-row-element-3">
      <label for="gestoreEmail">Email</label>
      <br />
      <input class="my-input" [placeholder]="placeholderEmail" type="text" id="gestoreEmail" name="gestoreEmail"
        formControlName="gestoreEmail" />
      <div *ngIf="getErrorsEmail('gestoreEmail')" class="errorInput">
        <p>Email non valida</p>
      </div>
    </div>

    <div class="my-row-element-3">
      <label for="gestoreMatricola">Matricola</label>
      <br />
      <input class="my-input" [placeholder]="placeholderMatricola" type="text" id="gestoreMatricola" name="gestoreMatricola"
        formControlName="gestoreMatricola" />
    </div>

    <div class="my-row-element-3">
      <label for="gestoreDescrizione">Descrizione</label>
      <br />
      <input class="my-input" [placeholder]="placeholderDescrizione" type="text" id="gestoreDescrizione" name="gestoreDescrizione"
        formControlName="gestoreDescrizione" />
    </div>

  </div>

  <div class="my-row following-row indent">

    <div class="my-row-element-3">
      <label>Opzioni Prenotazione</label>
      <section>
        <mat-checkbox #meet (change)="changeCheckbox(meet.value, meet)" formControlName="opzioniPrenotazioneConsulente"
          value="Meet" id="meet" style="margin-right: 10px;">Meet</mat-checkbox>
        <mat-checkbox #filiale (change)="changeCheckbox(filiale.value, filiale)"
          formControlName="opzioniPrenotazioneConsulente" value="Filiale" id="filiale">Filiale</mat-checkbox>
      </section>
    </div>

  </div>

   <div class="my-row following-row indent">
    <div class="my-row-element-3">
        <label for="argomento">Argomento</label>
        <br />
        <input class="my-input" type="text" id="argomento" name="argomento" formControlName="argomento" />

    </div>
  </div>

  <div class="my-button-container">
    <button class="my-button confirm" mat-raised-button (click)="save()">
      Invia
    </button>
  </div>
</form>
