import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '../../route/routes';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  @Input() viewBank!: boolean;
  @Input() viewBranches!: boolean;
  @Input() viewGestore!: boolean;
  @Input() viewReport!: boolean;
  @Input() viewIntegrationTest!: boolean;

  constructor(public router: Router) { }

  agency_url = ROUTES.AGENCY.path;
  bank_url = ROUTES.BANK.path;
  consultant_url = ROUTES.CONSULTANT.path;
  report_url = ROUTES.REPORT.path;
  tests_url = ROUTES.INTEGRATION_TEST.path

  ngOnInit(): void {
  }

  goToSettings(url: string) {
    this.router.navigate([url]);
  }

}
