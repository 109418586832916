<div class="container">
  <div class="breadcrumb">
    <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
    <span class="breadcrumb-current">Impostazioni Gestore</span>
  </div>

  <div class="menu-buttons">

    <div *ngIf="viewConsultant"
      class="menu-elements"
      matRipple
      [matRippleCentered]="false"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false"
      (click)="goTo('consultant')"
    >
      <div>
        <mat-icon svgIcon="associate-consultant" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Associazioni Filiale-Gestore</div>
    </div>
    
  </div>
</div>
