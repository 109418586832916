<div style="height: 670px;" *ngIf="spinnerView">
    <div class="spinner-loading">
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
</div>

<div class="container" id="sectionTop" *ngIf="!spinnerView">
    <div class="breadcrumb">
        <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
        <span class="breadcrumb-previous" (click)="goTo('landing')">Impostazioni Filiale</span> >
        <span class="breadcrumb-current">Nuova Filiale</span>
    </div>

    <div>
        <form name="myForm">
            <div class="my-row">
                <div class="my-row-element-3">
                    <label for="codeAgency">Codice Filiale:</label>
                    <br />
                    <input class="my-input" type="text" id="codeAgency" name="codeAgency" #my_input_6="ngModel"
                        [(ngModel)]="selectedAgency.CODE" autocomplete="off" maxlength="5" required />
                    <div class="errorInputAgency">
                        <p *ngIf="(my_input_6.dirty || my_input_6.touched) && isAgencyCodeInvalid()">Codice non valido
                        </p>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="filiale">Filiale:</label>
                    <br />
                    <input class="my-input" type="text" id="filiale" name="filiale" #my_input_1="ngModel"
                        [(ngModel)]="selectedAgency.name" autocomplete="off" minlength="1" required />
                    <div class="errorInputAgency">
                        <p *ngIf="my_input_1.invalid && (my_input_1.dirty || my_input_1.touched)">Nome filiale non
                            valido</p>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="address">Indirizzo:</label>
                    <br />
                    <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                     [options]="maps_options"  class="my-input" type="text"
                           id="address" name="address"
                        [ngModel]="selectedAgency.address" maxlength="100"  required />

                </div>
            </div>

            <div class="my-row following-row">
                <div class="my-row-element-3">
                    <label for="cap">CAP:</label>
                    <br />
                    <input class="my-input" type="text" id="cap" name="cap" #my_input_4="ngModel"
                        [(ngModel)]="selectedAgency.CAP" disabled autocomplete="off" maxlength="5" minlength="5" required />
                    <div class="errorInputAgency">
                        <p *ngIf="(my_input_4.dirty || my_input_4.touched) && isCAPInvalid()">CAP non valido</p>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="city">Città:</label>
                    <br />
                    <input class="my-input" type="text" id="city" name="city" #my_input_2="ngModel"
                        [(ngModel)]="selectedAgency.city_name" disabled autocomplete="off" minlength="1" required />
                    <div class="errorInputAgency">
                        <p *ngIf="my_input_2.invalid && (my_input_2.dirty || my_input_2.touched)">Città non valida</p>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="prov">Provincia:</label>
                    <br />
                    <input class="my-input" type="text" id="prov" name="provincia" #my_input_3="ngModel"
                        [(ngModel)]="selectedAgency.city_province" disabled autocomplete="off" maxlength="2" minlength="2"
                        required />
                    <div class="errorInputAgency">
                        <p *ngIf="(my_input_3.dirty || my_input_3.touched) && isProvinceInvalid()">Provincia non valida
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-row following-row">
                <div class="my-row-element-2">
                    <label for="orarioMattitino">Orario Mattutino:</label>
                    <div>
                        <mat-checkbox [(ngModel)]="isClosedMorning" name="morningCheckbox"
                            (change)="openings[0].reset()">
                            <label class="mini-label">Chiusa al mattino</label>
                        </mat-checkbox>
                    </div>
                    <div class="time-row" *ngIf="!isClosedMorning">
                        <div style="margin-right: 10px">
                            <label class="mini-label" for="aperturaMattino">Orario Apertura:</label>
                            <div>
                                <select class="my-select" name="hourOpeningMorning"
                                    [(ngModel)]="openings[0].start.hour">
                                    <option *ngFor="let item of ['07', '08', '09', '10', '11', '12', '13']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                                :
                                <select class="my-select" name="minutesOpeningMorning"
                                    [(ngModel)]="openings[0].start.minute">
                                    <option
                                        *ngFor="let item of ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div style="margin-left: 10px">
                            <label class="mini-label" for="chiusuraMattino">Orario Chiusura:</label>
                            <div>
                                <select class="my-select" name="hourClosingMorning"
                                    [(ngModel)]="openings[0].end.hour">
                                    <option *ngFor="let item of ['07', '08', '09', '10', '11', '12', '13']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                                :
                                <select class="my-select" name="minutesClosingMorning"
                                    [(ngModel)]="openings[0].end.minute">
                                    <option
                                        *ngFor="let item of ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="errorInputAgency">
                        <p *ngIf="(openings[0].start.hour != null && openings[0].end.minute != null) && openings[0].isInvalid()">Ora non valida</p>
                    </div>
                </div>

                <div class="my-row-element-2">
                    <label for="orarioPomeridiano">Orario Pomeridiano:</label>
                    <div>
                        <mat-checkbox [(ngModel)]="isClosedAfternoon" name="afternoonCheckbox"
                            (change)="openings[1].reset()">
                            <label class="mini-label">Chiusa al pomeriggio</label>
                        </mat-checkbox>
                    </div>
                    <div class="time-row" *ngIf="!isClosedAfternoon">
                        <div style="margin-right: 10px">
                            <label class="mini-label" for="aperturaPomeriggio">Orario Apertura:</label>
                            <div>
                                <select class="my-select" name="hourOpeningAfternoon"
                                    [(ngModel)]="openings[1].start.hour">
                                    <option *ngFor="let item of ['14', '15', '16', '17', '18', '19']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                                :
                                <select class="my-select" name="minutesOpeningAfternoon"
                                    [(ngModel)]="openings[1].start.minute">
                                    <option
                                        *ngFor="let item of ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div style="margin-left: 10px">
                            <label class="mini-label" for="chiusuraMattino">Orario Chiusura:</label>
                            <div>
                                <select class="my-select" name="hourClosingAfternoon" [(ngModel)]="openings[1].end.hour">
                                    <option *ngFor="let item of ['14', '15', '16', '17', '18', '19']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                                :
                                <select class="my-select" name="minutesClosingAfternoon"
                                    [(ngModel)]="openings[1].end.minute">
                                    <option
                                        *ngFor="let item of ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                                        [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="errorInputAgency">
                        <p *ngIf="(openings[1].start.hour != null && openings[1].end.minute != null) && openings[1].isInvalid()">Ora non valida</p>
                    </div>
                </div>

            </div>
            <div class="errorInputAgency">
                <p *ngIf="isClosedMorning && isClosedAfternoon">Impostare almeno un orario di apertura</p>
            </div>

            <div class="my-row following-row">
                <div class="my-row-element-2" *ngIf="canSeeCalendar">
                    <label for="calendarId">Calendar ID:</label>
                    <br />
                    <input class="my-input" type="text" id="calendarId" name="calendarId" #my_input_7="ngModel"
                        [(ngModel)]="selectedAgency.google_calendar_account"
                        autocomplete="off" minlength="1"/>
                    <div class="errorInputAgency">
                        <p *ngIf="my_input_7.invalid && (my_input_7.dirty || my_input_7.touched)">Calendar Id non
                            valido</p>
                    </div>
                </div>

                <div class="my-row-element-2">
                    <label for="statusAgency">Stato Filiale:</label>
                    <br />
                    <br />
                    <select id="statusAgency" class="my-select" name="stateAgency"
                        [(ngModel)]="selectedAgency.future_settings.status"
                        style="width: 60%;" required>
                        <option [ngValue]="undefined" [disabled]="true" hidden>
                            Scegli lo stato della Filiale
                        </option>
                        <option *ngFor="let option of status"
                            [ngValue]="option.value">{{option.label}}</option>
                    </select>
                </div>

            </div>
            <div class="my-row following-row" *ngIf="!canSeeCalendar">
                <div class="my-row-element-1">
                    <p class="legend">
                        N.B. Per poter attivare una filiale, sono necessari dei campi tecnici aggiuntivi.<br>
                        Contatta gli amministratori, dopo aver creato la filiale.
                    </p>
                </div>
            </div>

            <div class="my-row following-row">
                <div class="my-row-element-3">
                    <label for="service">Servizi:</label>
                    <div class="my-box">
                        <div class="my-box-element" *ngFor="let item of selectedAgency.agency_services">
                            {{ item.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.value}}
                            <button class="minusSmall" style="margin-left: 10px; color: white"
                                (click)="removeService(item.id_service)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="imgSmall">
                        <button class="plusSmall" (click)="openModal('service')" matRipple [matRippleCentered]="false"
                            [matRippleDisabled]="false" [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
                            style="color: white">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="chiusuraSett">Festività Patronali:</label>
                    <div class="my-box">
                        <div class="my-box-element" *ngFor="let item of selectedAgency.specific_holidays">
                            {{ item.festivity }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.date }}
                            <button class="minusSmall" style="margin-left: 10px; color: white"
                                (click)="removeFestivity(item.date)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="imgSmall">
                        <button class="plusSmall" (click)="openModal('specific_holidays')" matRipple [matRippleCentered]="false"
                            [matRippleDisabled]="false" [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
                            style="color: white">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="my-row-element-3">
                    <label for="chiusuraSett">Chiusure Straordinarie:</label>
                    <div class="my-box">
                        <div class="my-box-element" *ngFor="let item of selectedAgency.closings">
                            {{ item.start_date | date: "dd/MM/yyyy" }}&nbsp;-&nbsp;{{
                            item.end_date | date: "dd/MM/yyyy"
                            }}&nbsp;&nbsp;&nbsp;{{ typeAgency(item.type) }}
                            <button class="minusSmall" style="margin-left: 10px; color: white"
                                (click)="removeClosingDays(item)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="imgSmall">
                        <button class="plusSmall" (click)="openModal('closing')" matRipple [matRippleCentered]="false"
                            [matRippleDisabled]="false" [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
                            style="color: white">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

            </div>

            <div class="my-row following-row">
                <div class="my-row-element-1">
                    <label for="chiusuraSett">Chiusura Settimanale Cassa:</label>
                    <div class="my-box">
                        <table style="width: 100%;height: 100%;">
                            <tr>
                                <th class="first-column"></th>
                                <th class="first-row" *ngFor="let day of ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']">{{day}}</th>
                            </tr>
                            <tr>
                                <th class="first-column">Mattino:</th>
                                <th *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                                    <mat-checkbox
                                        [(ngModel)]="opening_days.morning[i]" name=m_{{i}}
                                        [disabled]="generalSettings.openings[i]=='0'">
                                    </mat-checkbox>
                                </th>
                            </tr>
                            <tr>
                                <th class="first-column">Pomeriggio:</th>
                                <th *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                                    <mat-checkbox
                                    [(ngModel)]="opening_days.afternoon[i]" name=p_{{i}}
                                    [disabled]="generalSettings.openings[i]=='0'">
                                    </mat-checkbox>
                                </th>
                            </tr>
                        </table>
                    </div>
                    <div >
                        <p class="legend">
                            Spunta i giorni nei quali la filiale è chiusa.<br />
                            I giorni in rosso sono disabilitati per tutte le filiali.
                        </p>
                    </div>
                </div>

            </div>
        </form>

        <div class="my-button-container">
            <button class="my-button confirm" mat-raised-button (click)="openModal('save')" [disabled]="isSaveDisabled()">
                SALVA
            </button>
        </div>
    </div>
</div>
