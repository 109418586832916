import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-report-landing',
  templateUrl: './report-landing.component.html',
  styleUrls: ['./report-landing.component.scss']
})
export class ReportLandingComponent implements OnInit {

  roles!: ROLES[];
  viewReportVIC!: boolean;
  viewReport!: boolean;
  
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      this.getRole();
    }
  }

  private getRole() {
    if (this.roles.includes(ROLES.ADMIN)) {
      this.viewReportVIC = true;
      this.viewReport = true;
    } else {
      if (this.roles.includes(ROLES.REPORT_CASSA_GESTORE)) {
        this.viewReport = true;
      }
      if (this.roles.includes(ROLES.CALLCENTER_ADMIN)){
        this.viewReportVIC = true;
      }
    }
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'report-vic':
        this.router.navigate([ROUTES.REPORT_EXTRACT_VIC.path]);
        break;
      case 'report':
        this.router.navigate([ROUTES.REPORT_EXTRACT.path]);
        break;
    }
  }

}
