<div class="container">
  <div mat-dialog-title class="title">Impostazioni Cassa</div>
  <mat-dialog-content class="mat-typography">
    <div class="inner-section">
      <div class="inner-title">Orari apertura cassa</div>
      <div class="inner-title" style="margin-top: 30px">Mattina</div>
      <div class="flex-time">
        <div class="time-table-flex">
          <label>Dalle:</label>
          <select autofocus="false" [(ngModel)]="orariMattina[1]">
            <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
          </select>
        </div>
        <div class="time-table-flex">
          <label>Alle:</label>
          <select class="select" [(ngModel)]="orariMattina[2]">
            <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
          </select>
        </div>
      </div>

      <div class="inner-title" style="margin-top: 30px">Pomeriggio</div>
      <div class="flex-time">
        <div class="time-table-flex">
          <label>Dalle:</label>
          <select [(ngModel)]="orariPomeriggio[1]">
            <option *ngFor="let pm of pomeriggio" [value]="pm">{{ pm }}</option>
          </select>
        </div>
        <div class="time-table-flex">
          <label>Alle:</label>
          <select class="select" [(ngModel)]="orariPomeriggio[2]">
            <option *ngFor="let pm of pomeriggio" [value]="pm">{{ pm }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="inner-section" style="margin-top: 20px">
      <div class="inner-title">Slot appuntamento in cassa</div>
      <div class="flex-time">
        <div class="time-table-flex">
          <label>Durata:</label>
          <select class="selectSlot" style="margin-left: 10px;" [(ngModel)]="durata">
            <option *ngFor="let item of durationList" [value]="item">
              {{ item }}
            </option>
          </select>
          min
        </div>
        <div class="time-table-flex">
          <label>Intervallo:</label>
          <select class="selectSlot" [(ngModel)]="buffer">
            <option *ngFor="let item of bufferList" [value]="item">
              {{ item }}
            </option></select
          >min
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="margin-top: 10px">
    <div
      matRipple
      [matRippleCentered]="false"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false"
      class="button-cancel"
      (click)="close()"
    >
      ANNULLA
    </div>
    <div
      matRipple
      [matRippleCentered]="false"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false"
      [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
      class="button-save"
      (click)="save()"
    >
      SALVA
    </div>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>
</div>
