import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  toggleString(bit: string) {
    if (bit == '1') {
      return '0';
    } else {
      return '1';
    }
  }

  replaceCharAt(item: string, index: number, replacement: string) {
    return (
      item.substr(0, index) +
      replacement +
      item.substr(index + replacement.length)
    );
  }

  scrollTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
