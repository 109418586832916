<div class="container">
  <div mat-dialog-title class="title">Impostazioni Giorni Apertura
    <form name="maMinForm" #maMinForm="ngForm">
      <div class="add-daystoreserve">
        <h2>Gestori</h2>
        <mat-form-field class="example-full-width">
          <mat-label>Primo giorno prenotabile</mat-label>
          <input required matInput
            [(ngModel)]="settingsOpening.consultant_reservation.consultant_min_days_reservation"
            name="consultant_min_days_reservation"
          >
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Ultimo giorno prenotabile</mat-label>
          <input required matInput
            [(ngModel)]="settingsOpening.consultant_reservation.consultant_max_days_reservation"
            name="consultant_max_days_reservation"
          >
        </mat-form-field>
      </div>

      <div class="add-daystoreserve-remote">
        <div style="width: 9%;">
          <h2>Gestori remoti</h2>
        </div>
        <mat-form-field class="example-full-width">
          <mat-label>Primo giorno prenotabile</mat-label>
          <input required matInput
            [(ngModel)]="settingsOpening.remote_consultant_reservation.remote_consultant_min_days_reservation"
            name="remote_consultant_min_days_reservation"
          >
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Ultimo giorno prenotabile</mat-label>
          <input required matInput
            [(ngModel)]="settingsOpening.remote_consultant_reservation.remote_consultant_max_days_reservation"
            name="remote_consultant_max_days_reservation"
          >
        </mat-form-field>
      </div>
    </form>
    <mat-dialog-content class="mat-typography">
      <div class="opening-days-form">
        <div>
          <h2>Cassa</h2>
          <table>
            <tr>
              <td>Lunedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 0)">
                  <div [ngClass]="
                    agencyOpenings[0] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Martedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 1)">
                  <div [ngClass]="
                    agencyOpenings[1] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Mercoledì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 2)">
                  <div [ngClass]="
                    agencyOpenings[2] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Giovedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 3)">
                  <div [ngClass]="
                    agencyOpenings[3] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Venerdì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 4)">
                  <div [ngClass]="
                    agencyOpenings[4] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Sabato</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 5)">
                  <div [ngClass]="
                    agencyOpenings[5] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Domenica</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(agencyType, 6)">
                  <div [ngClass]="
                    agencyOpenings[6] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <h2>Gestori</h2>
          <table>
            <tr>
              <td>Lunedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 0)">
                  <div [ngClass]="
                    consultantOpenings[0] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Martedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 1)">
                  <div [ngClass]="
                    consultantOpenings[1] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Mercoledì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 2)">
                  <div [ngClass]="
                    consultantOpenings[2] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Giovedì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 3)">
                  <div [ngClass]="
                    consultantOpenings[3] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Venerdì</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 4)">
                  <div [ngClass]="
                    consultantOpenings[4] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Sabato</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 5)">
                  <div [ngClass]="
                    consultantOpenings[5] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Domenica</td>
              <td>
                <div class="checkbox" (click)="toggleCheckbox(consultantType, 6)">
                  <div [ngClass]="
                    consultantOpenings[6] == '0' ? 'notSelected' : 'selected'
                  "></div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between;">
            <h2>Festività</h2>
            <div class="imgSmall">
              <div class="plusSmall" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
                [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'" (click)="addFestivityBanks()"
                style="color: white">
                <mat-icon>add</mat-icon>
              </div>
            </div>
          </div>
          <div>
            <div class="listContainer">
              <div class="daysFestivity" *ngFor="let item of nationalHolidays">
                {{ item.festivity }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.date }}
                <div class="minusSmall" style="color: white" (click)="removeFestivity(item.date)">
                  <mat-icon>remove</mat-icon>
                </div>
              </div>
              <div class="daysFestivity saveFestivity" *ngIf="addFestivity" style="height: 70px;">
                <div>
                  <input type="text" class="inputAddFestivity" [(ngModel)]="myArray.festivity" autocomplete="off"
                    placeholder="Festività" style="margin-top: 20px; margin-bottom: 5px;" />
                  <div>
                    <mat-form-field style="width: 70px;">
                      <mat-label>Giorno</mat-label>
                      <mat-select [(ngModel)]="daysModal">
                        <mat-option *ngFor="let item of daysModalFestivity" [value]="item">
                          {{item}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    -
                    <mat-form-field style="width: 70px;">
                      <mat-label>Mese</mat-label>
                      <mat-select [(ngModel)]="monthModal">
                        <mat-option *ngFor="let item of monthModalFestivity" [value]="item">
                          {{item}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div style="display: flex;">
                  <div class="add" (click)="saveAddFestivity()">
                    <mat-icon>done</mat-icon>
                  </div>
                  <div class="remove" (click)="closeAddFestivity()">
                    <mat-icon>close</mat-icon>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" id="btn">
      <div>
        <div *ngIf="errorNameFestivity">
          <p class="error" id="errorName">Errore:</p>
          <br />
          <span style="font-size: 16px; color: #20623b">Nome non valido</span>
        </div>
        <div *ngIf="errorDate">
          <p class="error" id="errorDate">Errore:</p>
          <br />
          <span style="margin-right: 10px; font-size: 16px; color: #20623b">Data non valida</span>
        </div>
      </div>
      <div style="display: flex; padding-top: 1rem;">
        <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false" [matRippleUnbounded]="false"
          class="button-cancel" (click)="close()">
          ANNULLA
        </div>
        <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false" [matRippleUnbounded]="false"
          [matRippleColor]="'rgb(255, 255, 255, 0.2)'" class="button-save" (click)="save()">
          SALVA
        </div>
      </div>
    </mat-dialog-actions>
  </div>
</div>