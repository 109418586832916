import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UtilsService } from '../../../services/utils.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BankSettingsOpenings } from 'src/app/model/bank-settings-openings.model';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-settings-opening-days',
  templateUrl: './settings-opening-days.component.html',
  styleUrls: ['./settings-opening-days.component.css'],
})
export class SettingsOpeningDaysComponent implements OnInit {

  agencyOpenings: string = '1111111';
  consultantOpenings: string = '1111111';
  agencyType: string = 'AGENCY';
  consultantType: string = 'CONSULTANT';

  nationalHolidays: any[] = [];
  addFestivity: boolean = false;
  errorDate: boolean = false;
  errorNameFestivity: boolean = false;
  myArray = { date: '', festivity: '' };
  inputFestivity: any;
  inputDateFestivity: any;

  daysModal: string | null = null;
  monthModal: string | null = null;
  daysModalFestivity = [
    '01', '02', '03', '04', '05',
    '06', '07', '08', '09', '10',
    '11', '12', '13', '14', '15',
    '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25',
    '26', '27', '28', '29', '30',
    '31'
  ];
  monthModalFestivity = [
    '01', '02', '03', '04', '05', '06',
    '07', '08', '09', '10', '11', '12'
  ];

  settingsOpening: BankSettingsOpenings = new BankSettingsOpenings();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SettingsOpeningDaysComponent>,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.agencyOpenings = this.data.cassa;
    this.consultantOpenings = this.data.gestori;
    this.nationalHolidays = this.data.closings;
    this.settingsOpening.consultant_reservation = this.data.consultant_reservation;
    this.settingsOpening.remote_consultant_reservation = this.data.remote_consultant_reservation;
  }

  toggleCheckbox(type: string, index: number) {
    console.log('checkbox');
    if (type == this.agencyType) {
      this.agencyOpenings = this.utilsService.replaceCharAt(
        this.agencyOpenings,
        index,
        this.utilsService.toggleString(this.agencyOpenings[index])
        );
    } else if (type == this.consultantType) {
      this.consultantOpenings = this.utilsService.replaceCharAt(
        this.consultantOpenings,
        index,
        this.utilsService.toggleString(this.consultantOpenings[index])
      );
    }
  }

  addFestivityBanks() {
    if (this.addFestivity == false) {
      this.addFestivity = true;
      this.errorDate = false;
      this.errorNameFestivity = false;
    } else {
      this.addFestivity = false;
      this.errorDate = false;
      this.errorNameFestivity = false;
    }
  }

  checkDateFestivity(event: any) {
    this.inputDateFestivity = event.target.value;
  }

  saveAddFestivity() {
    let days = this.daysModal + '-' + this.monthModal;

    if((this.daysModal == '')  || (this.daysModal == null)  || (this.monthModal == '')  || (this.monthModal == null)) {
      this.addFestivity = true;
      this.errorDate = true;
      const error = document.getElementById('btn');
      if (error != null) {
        error.style.justifyContent = 'space-between';
      }
    } else {
      if(this.myArray.festivity != undefined && this.myArray.festivity != '') {
        this.errorNameFestivity = false;
        this.errorDate = false;
        this.myArray = {date: days, festivity: this.myArray.festivity}
        this.nationalHolidays.push(this.myArray);
        this.addFestivity = false;
        this.errorDate = false;
        this.daysModal = '';
        this.monthModal = '';
        days = '';
        this.myArray = { date: '', festivity: '' };
      } else {
        const error = document.getElementById('btn');
        if (error != null) {
          error.style.justifyContent = 'space-between';
        }
        this.errorNameFestivity = true;
        this.errorDate = false;
      }

    }
  }

  closeAddFestivity() {
    this.addFestivity = false;
  }

  removeFestivity(obj: any) {
    this.nationalHolidays = this.nationalHolidays.filter(item => item.date != obj);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.settingsOpening.cassa = this.agencyOpenings;
    this.settingsOpening.gestori = this.consultantOpenings;
    this.settingsOpening.closings = this.nationalHolidays;
    this.dialogRef.close(this.settingsOpening);
  }

}

