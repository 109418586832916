<form [formGroup]="cassaForm">


  <h3>Dati Cliente:</h3>
  <div class="my-row indent">

    <div class="my-row-element-3">
      <label for="clienteName">Nome</label>
      <br />
      <input formControlName="clienteNome" class="my-input" type="text" id="clienteName" name="clienteName" />
    </div>

    <div class="my-row-element-3">
      <label for="clienteCognome">Cognome</label>
      <br />
      <input class="my-input" type="text" id="clienteCognome" name="clienteCognome" formControlName="clienteCognome" />
    </div>

    <div class="my-row-element-3">
      <label for="clienteIntestazione">Intestazione</label>
      <br />
      <input class="my-input" type="text" id="clienteIntestazione" name="clienteIntestazione"
        formControlName="clienteIntestazione" />
    </div>

  </div>

  <div class="my-row following-row indent">

    <div class="my-row-element-3">
      <label for="clienteEmail">Email</label>
      <br />
      <input class="my-input" type="email" id="clienteEmail" name="clienteEmail" formControlName="clienteEmail" />
      <div *ngIf="getErrorsEmail('clienteEmail')" class="errorInput">
        <p>Email non valida</p>
      </div>
    </div>

    <div class="my-row-element-3">
      <label for="clienteIndirizzo">Indirizzo</label>
      <br />
      <input class="my-input" type="text" id="clienteIndirizzo" name="clienteIndirizzo"
        formControlName="clienteIndirizzo" />
    </div>

    <div class="my-row-element-3">
      <label for="clienteCdg">CDG</label>
      <br />
      <input class="my-input" type="text" id="clienteCdg" name="clienteCdg" formControlName="clienteCdg" />
    </div>

  </div>

  <div class="my-row following-row indent">
    <div class="my-row-element-3">
      <label for="clienteRemoto">Cliente Remoto</label>
      <mat-radio-group aria-label="Cliente Remoto" formControlName="clienteRemoto">
        <mat-radio-button value=true style="margin-right: 10px;">Si</mat-radio-button>
        <mat-radio-button value=false>No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h3>Dati Generali:</h3>
  <div class="my-row indent">

    <div class="my-row-element-3">
      <label for="filialeCodice">Codice Filiale</label>
      <br />
      <input class="my-input" type="text" id="filialeCodice" name="filialeCodice" formControlName="filialeCodice" />
      <div *ngIf="getErrorsLength('filialeCodice') || getErrorsPattern('filialeCodice')" class="errorInput">
        <p *ngIf="getErrorsLength('filialeCodice')">Il campo Codice Filiale deve contenere 3 caratteri
        </p>
        <p *ngIf="getErrorsPattern('filialeCodice')">Il campo Codice Filiale deve essere composto da numeri
        </p>
      </div>
    </div>

    <div class="my-row-element-3">
      <label for="canaleUtilizzo">Canale Utilizzo</label>
      <br />
      <input class="my-input" type="text" id="canaleUtilizzo" name="canaleUtilizzo" formControlName="canaleUtilizzo"
        onkeyup="this.value = this.value.toUpperCase();" maxlength="3" />
      <div *ngIf="getErrorsLength('canaleUtilizzo')" class="errorInput">
        <p>Il campo Canale Utilizzo deve contenere esattamente tre caratteri</p>
      </div>
    </div>

  </div>

  <div class="my-button-container">
    <button class="my-button confirm" mat-raised-button (click)="save()" [disabled]="!cassaForm.valid">
      Invia
    </button>
  </div>
</form>
