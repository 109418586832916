import { ROLES } from "../model/user.model";

class Route {
    path: string = "";
    absolute_path: string = "";
    roles: ROLES[] = [];
}

export abstract class ROUTES {
    static readonly PRE_LANDING: Route = {
        path: "",
        absolute_path: "/",
        roles: []
    };

    static readonly NOT_ALLOWED: Route = {
        path: "not-allowed",
        absolute_path: "/not-allowed",
        roles: []
    };

    static readonly HOME: Route = {
        path: "home",
        absolute_path: "/home",
        roles: [ROLES.FILIALE, ROLES.BANCA, ROLES.CALLCENTER_ADMIN, ROLES.REPORT_CASSA_GESTORE, ROLES.ADMIN]
    };

    // AGENCY

    static readonly AGENCY: Route = {
        path: "filiale",
        absolute_path: "/filiale",
        roles: [ROLES.FILIALE, ROLES.ADMIN]
    }

    static readonly AGENCY_NEW: Route = {
        path: "filiale/nuova",
        absolute_path: "/filiale/nuova",
        roles: [ROLES.FILIALE, ROLES.ADMIN]
    }

    static readonly AGENCY_UPDATE: Route = {
        path: "filiale/modifica",
        absolute_path: "/filiale/modifica",
        roles: [ROLES.FILIALE, ROLES.ADMIN]
    }

    // BANK

    static readonly BANK: Route = {
        path: "banca",
        absolute_path: "/banca",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    static readonly BANK_EMAIL: Route = {
        path: "banca/email",
        absolute_path: "/banca/email",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    static readonly BANK_ORARI_GESTORE: Route = {
        path: "banca/orari-gestore",
        absolute_path: "/banca/orari-gestore",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    // CONSULTANT

    static readonly CONSULTANT: Route = {
        path: "gestore",
        absolute_path: "/gestore",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    static readonly CONSULTANT_AGENCY: Route = {
        path: "gestore/associazioni-filiali",
        absolute_path: "/gestore/associazioni-filiali",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    // REPORT

    static readonly REPORT: Route = {
        path: "report",
        absolute_path: "/report",
        roles: [ROLES.CALLCENTER_ADMIN, ROLES.REPORT_CASSA_GESTORE, ROLES.ADMIN]
    }

    static readonly REPORT_EXTRACT: Route = {
        path: "report/estrazione",
        absolute_path: "/report/estrazione",
        roles: [ROLES.REPORT_CASSA_GESTORE, ROLES.ADMIN]
    }

    static readonly REPORT_EXTRACT_VIC: Route = {
        path: "report/estrazione-vic",
        absolute_path: "/report/estrazione-vic",
        roles: [ROLES.CALLCENTER_ADMIN, ROLES.ADMIN]
    }

    // INTEGRATION TEST

    static readonly INTEGRATION_TEST: Route = {
      path: "integration-test",
      absolute_path: "/integration-test",
      roles: [ROLES.ADMIN]
    }

    // Add new Routes here

    static canAccess(roles: ROLES[], route: Route) {
        return route.roles.find(role => {return roles.includes(role)}) != undefined;
    }

    static getRoute(path: string) {
        return Object.entries(ROUTES).find(([key, value]) => {return value.absolute_path == path})
    }
}
