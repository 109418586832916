import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/route/routes';

@Component({
  selector: 'app-agency-landing',
  templateUrl: './agency-landing.component.html',
  styleUrls: ['./agency-landing.component.scss']
})
export class AgencyLandingComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'new':
        this.router.navigate([ROUTES.AGENCY_NEW.path]);
        break;
      case 'update':
        this.router.navigate([ROUTES.AGENCY_UPDATE.path]);
        break;
    }
  }

}
