<div class="container" id="section" *ngIf="!spinnerView">
    <div class="breadcrumb">
      <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
      <span class="breadcrumb-previous" (click)="goTo('landing')">Gestione Report</span> >
      <span class="breadcrumb-current">Estrazione Report - VIC</span>
    </div>

    <div>
      <label for="date">
          Scegli il periodo d'interesse:
      </label>
      <br>
      <div class="date">
        <div class="dateReset">
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Inserisci il periodo</mat-label>
              <mat-date-range-input [formGroup]="date" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Data di inizio">
                <input matEndDate formControlName="end" placeholder="Data di fine">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          
        </div>
        <div class="search">
          <button [ngClass]="isSearchDisabled() ? 'btnDisabled' : 'btn'" [disabled]="isSearchDisabled()" (click)="search()">Cerca</button>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z8" *ngIf="viewReservations">
      <table mat-table [dataSource]="dataSource" matSort>
    
          <ng-container matColumnDef="gestore">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Gestore
              </th>
              <td mat-cell *matCellDef="let element"> {{element.gestore}} </td>
          </ng-container>

          <ng-container matColumnDef="filiale">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Filiale
            </th>
            <td mat-cell *matCellDef="let element"> {{element.filiale}} </td>
          </ng-container>

          <ng-container matColumnDef="appuntamento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Data Appuntamento
            </th>
            <td mat-cell *matCellDef="let element"> {{element.data_appuntamento}} </td>
          </ng-container>

          <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cliente
            </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
          </ng-container>

          <ng-container matColumnDef="matricola">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Matricola Operatore
            </th>
            <td mat-cell *matCellDef="let element"> {{element.matricola}} </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nessun dato disponibile per il periodo selezionato.</td>
          </tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" 
                      showFirstLastButtons>
      </mat-paginator>
    </div>

    <div style="display: flex; justify-content: flex-end;">
      <div *ngIf="btnDownload" class="buttonDownload"
        matRipple
        [matRippleCentered]="false"
        [matRippleDisabled]="false"
        [matRippleUnbounded]="false"
        [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
        mat-button
        (click)="downloadFile()"
        >
        Scarica Report
        <img src="./../../assets/file_download_white_24dp.svg" style="margin-left: 20px;">
      </div>
    </div>
</div>

<div style="height: 670px;" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>