import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Consultant } from '../model/consultant.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl;
  }

  getConsultants(): Observable<any> {
    let url = this.api_prefix + "/consultants";
    return this.http.get(url)
  }

  createConsultant(consultant: Consultant): Observable<any> {
    let url = this.api_prefix + "/consultants";
    return this.http.post(url, consultant);
  }

  deleteConsultant(consultantId: number): Observable<any> {
    let url = this.api_prefix + `/consultants/${consultantId}`;
    return this.http.delete(url);
  }
}
