export class EmailSettings {
    status: boolean = true;
    templates: Array<EmailTemplate> = [];
    placeholders: Array<EmailPlaceholder> = [];
    blacklist: Array<EmailBlacklist> = [];
}

export class EmailTemplate {
    id: number = 0;
    name: string = "";
    template: string = "";
    type: string = "";
}

export class EmailPlaceholder {
    name: string = "";
    type: string = "";
}

export class EmailBlacklist {
    match: string = "";
    isFullmatch: boolean = false;
}