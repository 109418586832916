<div class="modal">
  <div>
    <label>Aggiungi una nuova festività:</label>
  </div>
  <div class="textModal">
    <label for="festivityModal" class="mini-label">
      Inserisci il nome della festività:
    </label>
    <input type="text" id="festivityModal" [(ngModel)]="inputData.festivity" autocomplete="off" class="my-input" />
  </div>
  <div>
    <label for="dateModal" class="mini-label">
      Inserisci la data della festività:
    </label>
    <mat-form-field>
      <mat-label>Inserisci la data</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="inputData.date" [min]="minDate" [max]="maxDate" name="datepicker"
        style="border-bottom: none;">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="year" [startAt]="minDate"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="my-button-container">
    <button class="my-button back" mat-raised-button (click)="close()">
      ANNULLA
    </button>
    <button class="my-button confirm" mat-raised-button (click)="saveData()"
      [disabled]="!inputData.festivity || !inputData.date">
      CONFERMA
    </button>
  </div>
</div>