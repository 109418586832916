import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportConsultant, ReportAgency } from 'src/app/model/report.model';
import { ROUTES } from 'src/app/route/routes';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { MyErrorHandler } from 'src/app/constants/error';
import { MatDialog } from '@angular/material/dialog';

import * as XLSX from 'xlsx';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-estrazione-report',
  templateUrl: './estrazione-report.component.html',
  styleUrls: ['./estrazione-report.component.scss']
})

export class EstrazioneReportComponent implements OnInit, AfterViewInit {

  date = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  private allData_agency: Array<ReportAgency> = [];
  private allData_consultant: Array<ReportConsultant> = [];
  private filteredArray: any =  [];

  // Queste sono le colonne che verranno mostrate in tabella
  displayedColumns: string[] = [];
  displayedColumns_agency: string[] = ['filiale', 'appuntamento', 'status', 'cliente', 'canale'];
  displayedColumns_consultant: string[] = ['gestore', 'appuntamento', 'status', 'tipologia', 'cliente', 'canale'];

  // Attenzione, l'ordine deve rispettare quello dei campi nella classe Report
  private xlsxColumns: string[][] = [[]];
  private xlsxColumns_agency: string[][] = [[
    "Filiale",
    "Data Appuntamento",
    "Data Creazione",
    "Stato Appuntamento",
    "Nome Cliente",
    "Email Cliente",
    "CDG Cliente",
    "Canale"
  ]];
  private xlsxColumns_consultant: string[][] = [[
    "Gestore",
    "Email Gestore",
    "Matricola Gestore",
    "Data Appuntamento",
    "Data Creazione",
    "Tipologia Appuntamento",
    "Argomento",
    "Note",
    "Stato Appuntamento",
    "Cliente",
    "Email Cliente",
    "CDG Cliente",
    "Telefono Cliente",
    "Canale",
    "Filiale"
  ]];

  dataSource = new MatTableDataSource();

  spinnerView!: boolean;
  btnDownload: boolean = false;
  viewReservations: boolean = false;

  dateStart: any = null;
  dateEnd: any = null;

  reportOptions = [
    {
      "id": 0,
      "name": "Appuntamenti in Cassa"
    },
    {
      "id": 1,
      "name": "Appuntamenti con Gestore"
    }
  ];
  typeSelected: any = null;
  private typeSelectedAfterSearch: any = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>,
    private datePipe : DatePipe,
    private reportService: ReportService
  ) {
    this.dateAdapter.setLocale('it-IT');
  }

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  search() {
    this.spinnerView = true;
    this.typeSelectedAfterSearch = this.typeSelected;
    let start_date = this.datePipe.transform(this.date.value.start, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.date.value.end, 'yyyy-MM-dd');

    if (!start_date || !end_date) return;

    if (this.typeSelected.id == 0) {
      // CASSA
      this.displayedColumns = this.displayedColumns_agency;
      this.xlsxColumns = this.xlsxColumns_agency;

      this.reportService.getAgencyReportData(start_date, end_date).subscribe(
        res => {
          this.allData_agency = new Array<ReportAgency>();

          res.data.forEach((element: any) => {
            let row = new ReportAgency(
              element.filiale,
              element.data_appuntamento,
              element.data_creazione,
              element.status,
              element.cliente,
              element.cliente_email,
              element.cliente_cdg,
              element.canale
            );

            this.allData_agency.push(row);
          });

          this.filteredArray = this.allData_agency;

          this.setDatasource();
        },
        err => {
          this.showError(err);
        }
      );
    } else if(this.typeSelected.id == 1) {
      // GESTORE
      this.displayedColumns = this.displayedColumns_consultant;
      this.xlsxColumns = this.xlsxColumns_consultant;

      this.reportService.getConsultantReportData(start_date, end_date).subscribe(
        res => {
          this.allData_consultant = new Array<ReportConsultant>();

          res.data.forEach((element: any) => {
            let row = new ReportConsultant(
              element.gestore,
              element.gestore_email,
              element.gestore_matricola,
              element.data_appuntamento,
              element.data_creazione,
              element.tipologia_appuntamento,
              element.argomento,
              element.note,
              element.status,
              element.cliente,
              element.cliente_email,
              element.cliente_cdg,
              element.cliente_telefono,
              element.canale,
              element.filiale,
            );

            this.allData_consultant.push(row);
          });

          this.filteredArray = this.allData_consultant;

          this.setDatasource();
        },
        err => {
          this.showError(err);
        }
      );
    } else {
      return;
    }
  }

  private setDatasource() {
    this.dataSource = new MatTableDataSource(this.filteredArray);
    this.viewReservations = true;

    if (this.filteredArray.length == 0) {
      this.btnDownload = false;
    } else {
      this.btnDownload = true;
    }

    this.spinnerView = false;
  }

  isSearchDisabled() {
    return !(this.date.value.start && this.date.value.end) || !this.typeSelected;
  }

  downloadFile(){
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(myworksheet, this.xlsxColumns);

    if (this.typeSelectedAfterSearch.id == 0) {
      XLSX.utils.sheet_add_json(myworksheet, this.allData_agency, { origin: 'A2', skipHeader: true });
    } else if (this.typeSelectedAfterSearch.id == 1) {
      XLSX.utils.sheet_add_json(myworksheet, this.allData_consultant, { origin: 'A2', skipHeader: true });
    } else {
      return;
    }

    const myworkbook: XLSX.WorkBook = { Sheets: { 'Report': myworksheet }, SheetNames: ['Report'] };

    let start_date = this.datePipe.transform(this.date.value.start, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.date.value.end, 'yyyy-MM-dd');

    const period = start_date + " _ " + end_date;

    XLSX.writeFile(myworkbook, period + ".xlsx");
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.REPORT.path]);
        break;
    }
  }
}
