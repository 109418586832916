import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient) { }

  getOpenings(): Observable<any> {
    let url = environment.backEndUrl + '/bank/openings';
    return this.http.get(url);
  }

  getSlots(): Observable<any> {
    let url = environment.backEndUrl + '/bank/slots';
    return this.http.get(url);
  }

  getEmailSettings(): Observable<any> {
    let url = environment.backEndUrl + '/bank/email';
    return this.http.get(url);
  }

  getTemplates(): Observable<any> {
    let url = environment.backEndUrl + '/bank/email/templates';
    return this.http.get(url);
  }

  getServiceSetting(): Observable<any> {
    let url = environment.backEndUrl + '/bank/services';
    return this.http.get(url);
  }

  updateOpenings(openings: any): Observable<any> {
    let url = environment.backEndUrl + '/bank/openings';
    return this.http.post(url, openings);
  }

  updateSlots(slots: any): Observable<any> {
    let url = environment.backEndUrl + '/bank/slots';
    return this.http.post(url, slots);
  }

  updateEmailStatus(status: any): Observable<any> {
    let url = environment.backEndUrl + '/bank/email/status';
    return this.http.post(url, status);
  }

  updateEmailTemplate(id: number, template: any): Observable<any> {
    let url = environment.backEndUrl + `/bank/email/templates/${id}`;
    return this.http.put(url, template);
  }

  updateEmailBlacklist(blacklist: any): Observable<any> {
    let url = environment.backEndUrl + '/bank/email/blacklist';
    return this.http.post(url, blacklist);
  }

  updateServiceSettings(services: any): Observable<any> {
    let url = environment.backEndUrl + '/bank/services';
    return this.http.post(url, services);
  }

  sendIntegrationTestJWT(body: any): Observable<any> {
    let url = environment.backEndUrl + '/reservations/jwt';
    return this.http.post(url, body);
  }

}
