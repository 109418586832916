import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyLandingComponent } from './component/agency/agency-landing/agency-landing.component';
import { AgencyNewComponent } from './component/agency/agency-new/agency-new.component';
import { AgencyUpdateComponent } from './component/agency/agency-update/agency-update.component';
import { BankSettingsComponent } from './component/bank/bank-landing/bank-settings.component';
import { AssociazioniFilialiComponent } from './component/consultant/associazioni-filiali/associazioni-filiali.component';
import { EstrazioneReportComponent } from './component/report/estrazione-report/estrazione-report.component';
import { EstrazioneReportVICComponent } from './component/report/estrazione-report-vic/estrazione-report-vic.component';
import { ConsultantLandingComponent } from './component/consultant/consultant-landing/consultant-landing.component';
import { MainPageComponent } from './landing/main-page/main-page.component';
import { NotAllowedComponent } from './landing/not-allowed/not-allowed.component';
import { PreLandingComponent } from './landing/pre-landing/pre-landing.component';
import { ROUTES } from './route/routes';
import { AuthGuard } from './auth/auth.guard';
import { BankEmailComponent } from './component/bank/bank-email/bank-email.component';
import { ReportLandingComponent } from './component/report/report-landing/report-landing.component';
import { IntegrationTestParentComponent } from './component/integration-test/integration-test-parent/integration-test-parent.component';

const routes: Routes = [
  {
    path: ROUTES.PRE_LANDING.path,
    component: PreLandingComponent,
    canActivate: []
  },
  {
    path: ROUTES.HOME.path,
    component: MainPageComponent,
    canActivate: [AuthGuard],
  },
  // AGENCY
  {
    path: ROUTES.AGENCY.path,
    component: AgencyLandingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.AGENCY_NEW.path,
    component: AgencyNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.AGENCY_UPDATE.path,
    component: AgencyUpdateComponent,
    canActivate: [AuthGuard],
  },
  // BANK
  {
    path: ROUTES.BANK.path,
    component: BankSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES.BANK_EMAIL.path,
    component: BankEmailComponent,
    canActivate: [AuthGuard]
  },
  // CONSULTANT
  {
    path: ROUTES.CONSULTANT.path,
    component: ConsultantLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.CONSULTANT_AGENCY.path,
    component: AssociazioniFilialiComponent,
    canActivate: [AuthGuard],
  },
  // REPORT
  {
    path: ROUTES.REPORT.path,
    component: ReportLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.REPORT_EXTRACT.path,
    component: EstrazioneReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.REPORT_EXTRACT_VIC.path,
    component: EstrazioneReportVICComponent,
    canActivate: [AuthGuard],
  },
  // INTEGRATION TEST
  {
    path: ROUTES.INTEGRATION_TEST.path,
    component: IntegrationTestParentComponent,
    canActivate: [AuthGuard],
  },
  // OTHER
  {
    path: ROUTES.NOT_ALLOWED.path,
    component: NotAllowedComponent,
    canActivate: [],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
