import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-service',
  templateUrl: './settings-service.component.html',
  styleUrls: ['./settings-service.component.css'],
})
export class SettingsServiceComponent implements OnInit {
  services: any[] = [];
  newServices: any[] = [];
  newService: boolean = false;
  nameService!: string;
  activeService: any;
  disabledService: any;
  servizi = { id_service: undefined, name: '', active: 1 };
  errors: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<SettingsServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (
      (this.data.services = this.data.services.filter(
        (item: any) => item.id_service != undefined
      ))
    ) {
      this.services = this.data.services;
    }
  }

  close() {
    this.dialogRef.close();
  }

  removeService(item: any, index: number) {
    console.log(item, index);
    this.services.splice(index, 1);
    item.active = -1;
    this.newServices.push(item);
    console.log(this.newServices);
    console.log(this.services);
  }

  setServices(event: any, item: any) {
    console.log(item);
    let index = this.newServices.findIndex(
      (element) =>
        element.id_service == item.id_service && element.name == item.name
    );
    if (index >= 0) {
      this.newServices[index] = item;
    } else {
      this.newServices.push(item);
    }
  }

  addService() {
    if (this.nameService != undefined && this.nameService != '') {
      if (
        this.services.findIndex((element) => element.name == this.nameService) <
        0
      ) {
        this.newServices.push({
          id_service: undefined,
          name: this.nameService,
          active: 1,
        });
        this.services.push({
          id_service: undefined,
          name: this.nameService,
          active: 1,
        });
        console.log(this.services);
        this.nameService = '';
        this.errors = [];
      } else {
        this.errors.push('Esiste già un servizio con questo nome');
      }
    } else {
      console.log('error');
      this.errors.push('Inserire un nome per il servizio da inserire');
    }
  }

  save() {
    console.log(this.newServices);
    let request = { services: this.newServices };
    this.dialogRef.close(request);
  }
}
