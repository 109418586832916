import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-consultants',
  templateUrl: './settings-consultants.component.html',
  styleUrls: ['./settings-consultants.component.css'],
})
export class SettingsConsultantsComponent implements OnInit {
  buffer: string = '';
  durata: string = '';
  morningHours: string[][] = [];
  afternoonHours: string[][] = [];
  morningHoursRemote: string[][] = [];
  afternoonHoursRemote: string[][] = [];
  errors: string[] = [];

  mattina = [
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
  ];

  pomeriggio = [
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00'
  ];

  slot1 = new Array<string>();
  slot2 = new Array<string>();
  slot3 = new Array<string>();

  selected = 'gestori'

  constructor(
    public dialogRef: MatDialogRef<SettingsConsultantsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.morningHours = this.data.gestori.orari.filter(this.isMorning);
    this.afternoonHours = this.data.gestori.orari.filter(this.isAfternoon);
    this.morningHoursRemote = this.data.gestori_remoti.orari.filter(this.isMorning);
    this.afternoonHoursRemote = this.data.gestori_remoti.orari.filter(this.isAfternoon);

    this.slot1 = this.data.gestori.orari[0];
    this.slot2 = this.data.gestori.orari[1];
    this.slot3 = this.data.gestori.orari[2];
    this.buffer = this.data.buffer;
    this.durata = this.data.durata;
  }

  isMorning(element: string[], index: number, array: any[]) {
    return element[0] == 'M';
  }

  isAfternoon(element: string[], index: number, array: any[]) {
    return element[0] == 'P';
  }

  close() {
    this.dialogRef.close();
  }

  selectChange() {
    console.log(this.selected)
    if (this.selected === 'gestori-remoti') {
      this.slot1 = this.data.gestori_remoti.orari[0];
      this.slot2 = this.data.gestori_remoti.orari[1];
      this.slot3 = this.data.gestori_remoti.orari[2];
    } else {
      this.slot1 = this.data.gestori.orari[0];
      this.slot2 = this.data.gestori.orari[1];
      this.slot3 = this.data.gestori.orari[2];
    }
  }

  save() {
    this.errors = [];
    let orari: string[][] = this.morningHours.concat(this.afternoonHours);
    let orariRemote: string[][] = this.morningHoursRemote.concat(this.afternoonHoursRemote);

    this.generaOrario(orari)
    this.generaOrario(orariRemote)

    let request = {
      gestori: {
        orari: orari,
      },
      gestori_remoti: {
        orari: orariRemote,
      }
    };

    this.dialogRef.close(request);
  }

  generaOrario(orari: string[][]) {

    if (orari.length == 0) {
      this.errors.push('Inserire almeno uno slot');
    } else if (
      orari.some((element) => element[1] == '--:--' || element[2] == '--:--')
    ) {
      console.log('illegal argument --:--');
      this.errors.push('Slot con orario non selezionato');
    }

  }

  removeSlot(dayTime: string, index: number) {
    if (dayTime == 'M') {
      if (index > -1) {
        if (this.selected === 'gestori') {
          this.morningHours.splice(index, 1);
        } else {
          this.morningHoursRemote.splice(index, 1);
        }

      }
    } else {
      if (index > -1) {
        if (this.selected === 'gestori') {
          this.afternoonHours.splice(index, 1);
        } else {
          this.afternoonHoursRemote.splice(index, 1);
        }
      }
    }
  }

  addSlot(dayTime: string) {
    if (dayTime == 'M') {
      if (this.selected === 'gestori') {
        this.morningHours.push([dayTime, '--:--', '--:--']);
      } else {
        this.morningHoursRemote.push([dayTime, '--:--', '--:--']);
      }
    } else {
      if (this.selected === 'gestori') {
        this.afternoonHours.push([dayTime, '--:--', '--:--']);
      } else {
        this.afternoonHoursRemote.push([dayTime, '--:--', '--:--']);
      }
    }
  }

}
