import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-cash-desks',
  templateUrl: './settings-cash-desks.component.html',
  styleUrls: ['./settings-cash-desks.component.css'],
})
export class SettingsCashDesksComponent implements OnInit {
  buffer: string = '';
  durata: string = '';

  durationList = ['10', '15', '20', '25', '30'];
  bufferList = ['0', '5', '10', '15'];

  mattina = [
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
  ];

  pomeriggio = [
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
  ];

  orariMattina = new Array<string>();
  orariPomeriggio = new Array<string>();

  constructor(
    public dialogRef: MatDialogRef<SettingsCashDesksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.orariMattina = this.data.orari[0];
    this.orariPomeriggio = this.data.orari[1];
    this.buffer = this.data.buffer;
    this.durata = this.data.durata;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let request = {
      cassa: {
        orari: [this.orariMattina, this.orariPomeriggio],
        buffer: +this.buffer,
        durata: +this.durata,
      },
    };
    console.log(request);
    this.dialogRef.close(request);
    //this.bankService.updateSlots();
  }
}
