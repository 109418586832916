<div class="container" *ngIf="!spinnerView">
    <div class="breadcrumb">
      <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
      <span class="breadcrumb-previous" (click)="goTo('landing')">Impostazioni Gestore</span> >
      <span class="breadcrumb-current">Associazione Filiale-Gestore</span>
    </div>

    <div>
      <mat-form-field appearance="standard">
        <mat-label>Filtra</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Es. Giorgio" autocomplete="off" #input style="border-bottom: none;">
      </mat-form-field>

      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
          <ng-container matColumnDef="agency_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Numero Filiale
            </th>
            <td mat-cell *matCellDef="let element"> {{element.agency_code}} </td>
          </ng-container>
      
          <ng-container matColumnDef="agency_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Nome filiale
            </th>
            <td mat-cell *matCellDef="let element"> {{element.agency_name}} </td>
          </ng-container>
          
          <ng-container matColumnDef="consultant_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Nome
            </th>
            <td mat-cell *matCellDef="let element"> {{element.consultant_name}} </td>
          </ng-container>

          <ng-container matColumnDef="consultant_surname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cognome
            </th>
            <td mat-cell *matCellDef="let element"> {{element.consultant_surname}} </td>
          </ng-container>

          <ng-container matColumnDef="matricola">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Matricola
            </th>
            <td mat-cell *matCellDef="let element"> {{element.matricola}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
            </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 20px;">
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="minusSmall" style="margin-left: 10px; color: white" (click)="removeConsultant(element)">
                <mat-icon>remove</mat-icon>
              </div>
            </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" 
                      showFirstLastButtons>
      </mat-paginator>
    </div>
    
    <div class="create">
      <div>
        <label>Aggiungi un nuovo gestore</label>
      </div>
      <div class="imgSmall">
        <div
        class="plusSmall"
        (click)="showForm()"
        matRipple
        [matRippleCentered]="false"
        [matRippleDisabled]="false"
        [matRippleUnbounded]="false"
        [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
        style="color: white"
        >
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div>

    <div class="viewForm" *ngIf="viewForm">
      <form>
        <div class="my-row">
      
          <mat-form-field class="my-form-field-color my-row-element-3" appearance="fill" style="margin-bottom: -17px;">
            <mat-label>Scegli una filiale</mat-label>
            <input type="text" placeholder="Inserisci" aria-label="Text" matInput name="agencyAutocomplete"
              [(ngModel)]="inputAgencyName" (ngModelChange)="doFilterAutocomplete()" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAgency()">
              <mat-option *ngFor="let agency of filteredAgencies" [value]="agency.name">
                {{agency.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      
          <div class="my-row-element-3">
            <label for="name">Nome Gestore:</label>
            <br />
            <input class="my-input" type="text" id="name" name="nameGestore" [(ngModel)]="consultant.name"
              autocomplete="off" />
          </div>
      
          <div class="my-row-element-3">
            <label for="lastname">Cognome Gestore:</label>
            <br />
            <input class="my-input" type="text" id="lastname" name="lastnameGestore" [(ngModel)]="consultant.surname"
              autocomplete="off" />
          </div>
      
        </div>
      
        <div class="my-row following-row">
      
          <div class="my-row-element-3">
            <label for="manager">Email:</label>
            <br />
            <input class="my-input" type="text" id="manager" name="emailGestore" [(ngModel)]="consultant.email"
              autocomplete="off" />
          </div>
      
          <div class="my-row-element-3">
            <label for="matricola">Matricola:</label>
            <br />
            <input class="my-input" type="text" id="matricola" name="matricolaGetsore" [(ngModel)]="consultant.matricola"
              autocomplete="off" />
          </div>

          <div class="my-row-element-3"></div>
      
        </div>
      </form>

      <div class="my-button-container">
        <button class="my-button back" mat-raised-button (click)="resetForm()">
          ANNULLA
        </button>
        <button class="my-button confirm" mat-raised-button (click)="save()"
          [disabled]="isSaveDisabled()">
          SALVA
        </button>
      </div>
    </div>

</div>

<div style="height: 670px;" *ngIf="spinnerView">
    <div class="spinner-loading">
      <div>
        <span class="spinner-double-dot-stick"></span>
      </div>
    </div>
</div>