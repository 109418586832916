export class MyErrorHandler {
    static getText(error: any) {
        let resp: MyError = new MyError();

        if(error.status == 500) {
            resp.customText = GENERIC_ERROR;
            resp.customDetails = "Codice: 000";
        } else if(error.error){
            resp.customText = HANDLED_ERROR;
            resp.customDetails = JSON.stringify(error.error);
        } else {
            resp.customText = UNHANDLED_ERROR;
            resp.customDetails = "Codice: 001";
            console.error(error);
        }

        return resp;
    }
}

export class MyError {
    customText: string | undefined;
    customDetails: string | undefined;
}

const GENERIC_ERROR: string = "Si è verificato un errore interno. Contatta l'assistenza per ricevere supporto.";
const UNHANDLED_ERROR : string = "Si è verificato un errore. Contatta l'assistenza per ricevere supporto."
const HANDLED_ERROR: string = "Si è verificato un errore. Per risolvere, comunica i dettagli all'assistenza.";