<div style="height: 670px;" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div class="container" *ngIf="!spinnerView">
  <div class="breadcrumb">
    <span class="breadcrumb-previous" (click)="goToHome()">Home</span> >
    <span class="breadcrumb-current">Impostazioni Banca</span>
  </div>

  <div class="menu-buttons">

    <div class="menu-elements" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
      [matRippleUnbounded]="false" (click)="openSettingsCashDesks()">
      <div>
        <mat-icon svgIcon="watch-later" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Impostazioni Orari Casse</div>
    </div>

    <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false" [matRippleUnbounded]="false"
      class="menu-elements" (click)="openSettingsConsultants()">
      <div>
        <mat-icon svgIcon="pending-actions" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Impostazioni Orari Gestori</div>
    </div>

    <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false" [matRippleUnbounded]="false"
      class="menu-elements" (click)="openSettingsOpeningDays()">
      <div>
        <mat-icon svgIcon="event" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Impostazioni Giorni Apertura</div>
    </div>

    <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
      [matRippleUnbounded]="false" class="menu-elements" (click)="openEmailManagement()">
      <div>
        <mat-icon svgIcon="mail" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Gestione email</div>
    </div>

    <div matRipple [matRippleCentered]="false" [matRippleDisabled]="false" [matRippleUnbounded]="false"
      class="menu-elements" (click)="openSettingService()">
      <div>
        <mat-icon svgIcon="service" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Gestione servizi</div>
    </div>
    
  </div>
</div>