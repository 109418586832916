import { Flow, FlowCassa, FlowGestore } from '../../../model/integration-test.model';
import { BankService } from '../../../services/bank.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/route/routes';

@Component({
  selector: 'app-integration-test-parent',
  templateUrl: './integration-test-parent.component.html',
  styleUrls: ['./integration-test-parent.component.scss']
})
export class IntegrationTestParentComponent implements OnInit {

  spinnerView: boolean = false;
  selected = '';
  showCassa = false;
  showGestore = false;
  output!: Flow;

  constructor(private router: Router,
    private bankService: BankService
  ) { }

  ngOnInit(): void {
    this.selection();
  }

  goTo(path: string) {
    switch (path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
    }
  }

  save(field: any) {
    this.spinnerView = true;
    let flow = '';

    if(field instanceof FlowCassa) {
      flow = 'CASSA'
    } else if(field instanceof FlowGestore) {
      flow = 'GESTORE'
    }

    this.output = {
      flow: flow,
      jwt: field
    }

    this.bankService.sendIntegrationTestJWT(this.output).subscribe({
        next: (result) => {
          // var localLink = 'http://localhost:4200?id='+result.split('=')[1]
          // console.log(localLink)
          window.location.href = result
          //window.location.href = localLink
        },
        error: (error) => {
          console.log(error)
        }
      })

  }

  selection() {
    if(this.selected === 'cassa') {
      this.showCassa = true
      this.showGestore = false
    } else if(this.selected === 'gestore') {
      this.showCassa = false
      this.showGestore = true
    }
  }

}
