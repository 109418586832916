import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyErrorHandler } from 'src/app/constants/error';
import { SettingsCashDesksComponent } from 'src/app/modal/modal-banks/settings-cash-desks/settings-cash-desks.component';
import { SettingsConsultantsComponent } from 'src/app/modal/modal-banks/settings-consultants/settings-consultants.component';
import { SettingsOpeningDaysComponent } from 'src/app/modal/modal-banks/settings-opening-days/settings-opening-days.component';
import { SettingsServiceComponent } from 'src/app/modal/modal-banks/settings-service/settings-service.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { User } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { BankService } from 'src/app/services/bank.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-bank-settings',
  templateUrl: './bank-settings.component.html',
  styleUrls: ['./bank-settings.component.css'],
})
export class BankSettingsComponent implements OnInit {
  openings: any;
  slots: any;
  services: any;
  spinnerView: boolean = true;
  user!: User;

  constructor(
    private router: Router,
    private bankService: BankService,
    private dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.initBankInfo();
    }
  }

  initBankInfo() {
    this.getBankOpenings();
    this.getBankSlots();
    this.getServiceSetting();
  }

  goToHome() {
    this.router.navigate([ROUTES.HOME.path]);
  }

  openEmailManagement() {
    this.router.navigate([ROUTES.BANK_EMAIL.path]);
  }

  openSettingService() {
    const dialogRef = this.dialog.open(SettingsServiceComponent, {
      data: this.services,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerView = true;
        this.bankService.updateServiceSettings(result).subscribe(
          data => {
            this.initBankInfo();
            this.showSuccess();
          },
          err => {
            this.showError(err);
          }
        );
      }
    });
  }

  openSettingsCashDesks() {
    const dialogRef = this.dialog.open(SettingsCashDesksComponent, {
      data: this.slots.cassa,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerView = true;
        this.bankService.updateSlots(result).subscribe(
          data => {
            this.initBankInfo();
            this.showSuccess();
          },
          err => {
            this.showError(err);
          }
        );
      }
    });
  }

  openSettingsConsultants() {
    const dialogRef = this.dialog.open(SettingsConsultantsComponent, {
      data: this.slots,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerView = true;
        this.bankService.updateSlots(result).subscribe(
          data => {
            this.initBankInfo();
            this.showSuccess();
          },
          err => {
            this.showError(err);
          }
        );
      }
    });
  }

  openSettingsOpeningDays() {
    const dialogRef = this.dialog.open(SettingsOpeningDaysComponent, {
      data: this.openings,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerView = true;
        this.bankService.updateOpenings(result).subscribe(
          data => {
            this.initBankInfo();
            this.showSuccess();
          },
          err => {
            this.showError(err);
          }
        );
      }
    });
  }

  getBankOpenings() {
    this.bankService.getOpenings().subscribe((res) => {
      this.openings = res;
      this.spinnerView = false;
    });
  }

  getBankSlots() {
    this.bankService.getSlots().subscribe((res) => {
      this.slots = res;
    });
  }

  getServiceSetting() {
    this.bankService.getServiceSetting().subscribe(res => {
      this.services = res;
    })
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goToHome();
    });
  }

  private showSuccess() {
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
