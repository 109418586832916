import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportVIC } from 'src/app/model/report.model';
import { ROUTES } from 'src/app/route/routes';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { MyErrorHandler } from 'src/app/constants/error';
import { MatDialog } from '@angular/material/dialog';

import * as XLSX from 'xlsx';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-estrazione-report-vic',
  templateUrl: './estrazione-report-vic.component.html',
  styleUrls: ['./estrazione-report-vic.component.scss']
})

export class EstrazioneReportVICComponent implements OnInit, AfterViewInit {

  date = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  private allData: Array<ReportVIC> = [];
  private filteredArray: any =  [];

  displayedColumns: string[] = ['gestore', 'filiale', 'appuntamento', 'cliente', 'matricola'];
  
  // Attenzione, l'ordine deve rispettare quello dei campi nella classe Report
  private xlsxColumns: string[][] = [[
    "Gestore",
    "Filiale",
    "Data Appuntamento",
    "Data Creazione",
    "Tipologia Appuntamento",
    "Argomento",
    "Note",
    "Codice Fiscale",
    "Cliente",
    "Matricola Operatore"
  ]];
  
  dataSource = new MatTableDataSource();

  spinnerView!: boolean;
  btnDownload: boolean = false;
  viewReservations: boolean = false;

  dateStart: any;
  dateEnd: any;


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>,
    private datePipe : DatePipe,
    private reportService: ReportService
  ) {
    this.dateAdapter.setLocale('it-IT');
  }

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {  
  }

  search() {
    this.spinnerView = true;
    let start_date = this.datePipe.transform(this.date.value.start, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.date.value.end, 'yyyy-MM-dd');

    if (!start_date || !end_date) return;

    this.reportService.getVICReportData(start_date, end_date).subscribe(
      res => {
        this.allData = new Array<ReportVIC>();
        
        res.data.forEach((element: any) => {
          let row = new ReportVIC(
            element.gestore,
            element.filiale,
            element.data_appuntamento,
            element.data_creazione,
            element.tipologia_appuntamento,
            element.argomento,
            element.note,
            element.codice_fiscale,
            element.cliente,
            element.matricola_operatore
          );

          this.allData.push(row);
        });

        this.filteredArray = this.allData;

        this.dataSource = new MatTableDataSource(this.filteredArray);
        this.viewReservations = true;

        if (this.filteredArray.length == 0) {
          this.btnDownload = false;
        } else {
          this.btnDownload = true;
        }

        this.spinnerView = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  isSearchDisabled() {
    return !(this.date.value.start && this.date.value.end);
  }

  downloadFile(){
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(myworksheet, this.xlsxColumns);
    XLSX.utils.sheet_add_json(myworksheet, this.allData, { origin: 'A2', skipHeader: true });
    const myworkbook: XLSX.WorkBook = { Sheets: { 'Report': myworksheet }, SheetNames: ['Report'] };

    let start_date = this.datePipe.transform(this.date.value.start, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.date.value.end, 'yyyy-MM-dd');

    const period = start_date + " _ " + end_date;

    XLSX.writeFile(myworkbook, period + ".xlsx");
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.REPORT.path]);
        break;
    }
  }
}
