export class BankSettingsOpenings {
    cassa!: string;
    closings!: Array<Closings>;
    gestori!: string;
    consultant_reservation!: {
        consultant_min_days_reservation: number,
        consultant_max_days_reservation: number
    };
    remote_consultant_reservation!: {
        remote_consultant_min_days_reservation: number,
        remote_consultant_max_days_reservation: number
    };
}

class Closings {
    date!: string;
    festivity!: string;
}
