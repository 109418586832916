export class ReportVIC {
    gestore: string;
    filiale: string;
    data_appuntamento: string;
    data_creazione: string;
    tipologia_appuntamento: string;
    argomento: string;
    note: string;
    CF_cliente: string;
    cliente: string;
    matricola: string;

    constructor(
        gestore: string, filiale: string,
        data: string, data_creazione:string,
        tipologia: string, argomento: string,
        note: string, CF: string,
        cliente: string, matricola: string
        ) {
        this.gestore = gestore;
        this.filiale = filiale;
        this.data_appuntamento = data;
        this.data_creazione = data_creazione;
        this.tipologia_appuntamento = tipologia;
        this.argomento = argomento;
        this.note = note;
        this.CF_cliente = CF;
        this.cliente = cliente;
        this.matricola = matricola;
    }
}

export class ReportConsultant {
    gestore: string;
    gestore_email: string;
    gestore_matricola: string;
    data_appuntamento: string;
    data_creazione: string;
    tipologia_appuntamento: string;
    argomento: string;
    note: string;
    status: string;
    cliente: string;
    cliente_email: string;
    cliente_cdg: string;
    cliente_telefono: string;
    canale: string;
    filiale: string;

    constructor(
        gestore: string, email_gestore: string,
        matricola_gestore: string,
        data: string, data_creazione:string,
        tipologia: string, argomento: string,
        note: string, status: string,
        cliente: string, email_cliente: string,
        cdg_cliente: string, telefono_cliente: string,
        canale: string,
        filiale: string,
        ) {
        this.gestore = gestore;
        this.gestore_email = email_gestore;
        this.gestore_matricola = matricola_gestore;
        this.data_appuntamento = data;
        this.data_creazione = data_creazione;
        this.tipologia_appuntamento = tipologia;
        this.argomento = argomento;
        this.note = note;
        this.status = status;
        this.cliente = cliente;
        this.cliente_email = email_cliente;
        this.cliente_cdg = cdg_cliente;
        this.cliente_telefono = telefono_cliente;
        this.canale = canale;
        this.filiale = filiale;
    }
}

export class ReportAgency {
    filiale: string;
    data_appuntamento: string;
    data_creazione: string;
    status: string;
    cliente: string;
    cliente_email: string;
    cliente_cdg: string;
    canale: string;

    constructor(
        filiale: string,
        data: string, data_creazione:string,
        status: string, cliente: string,
        email_cliente: string,
        cdg_cliente: string,
        canale: string
        ) {
        this.filiale = filiale;
        this.data_appuntamento = data;
        this.data_creazione = data_creazione;
        this.status = status;
        this.cliente = cliente;
        this.cliente_email = email_cliente;
        this.cliente_cdg = cdg_cliente;
        this.canale = canale;
    }
}
