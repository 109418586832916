<div class="container">
  <div mat-dialog-title class="title">Impostazioni Gestori</div>

  <mat-form-field class="my-form-field-color my-row-element-2" appearance="fill">
    <mat-label>Seleziona tipo di gestore</mat-label>
    <mat-select #tipoGestore (selectionChange)="selectChange()" [(ngModel)]="selected">
      <mat-option value="gestori" selected>Gestori</mat-option>
      <mat-option value="gestori-remoti">Gestori remoti</mat-option>
    </mat-select>
  </mat-form-field>


  <mat-dialog-content class="mat-typography">
    <div class="inner-section">
      <div *ngIf="tipoGestore.value === 'gestori'">
        <div class="inner-title">Orari apertura gestori</div>
        <br />

        <div class="daytime-title">Mattina</div>
        <div *ngFor="let item of morningHours; let i = index">
          <div class="flex-time">
            <div class="time-table-flex">
              <label>Dalle:</label>
              <select [(ngModel)]="item[1]">
                <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
              </select>
            </div>
            <div class="time-table-flex">
              <label>Alle:</label>
              <select class="select" [(ngModel)]="item[2]">
                <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
              </select>
            </div>
            <div class="minusSmall" style="color: white" (click)="removeSlot('M', i)">
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </div>
        <div class="add-slot">
          <div class="plusSmall" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'" (click)="addSlot('M')"
            style="color: white">
            <mat-icon>add</mat-icon>
          </div>
        </div>
        <hr />
        <div class="daytime-title">Pomeriggio</div>
        <div *ngFor="let item of afternoonHours; let i = index">
          <div class="flex-time">
            <div class="time-table-flex">
              <label>Dalle:</label>
              <select [(ngModel)]="item[1]">
                <option *ngFor="let am of pomeriggio" [value]="am">
                  {{ am }}
                </option>
              </select>
            </div>
            <div class="time-table-flex">
              <label>Alle:</label>
              <select class="select" [(ngModel)]="item[2]">
                <option *ngFor="let am of pomeriggio" [value]="am">
                  {{ am }}
                </option>
              </select>
            </div>
            <div class="minusSmall" style="color: white" (click)="removeSlot('P', i)">
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </div>
        <div class="add-slot">
          <div class="plusSmall" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'" (click)="addSlot('P')"
            style="color: white">
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </div>

      <div *ngIf="tipoGestore.value === 'gestori-remoti'">
        <div class="inner-title">Orari apertura gestori remoti</div>
        <br />

        <div class="daytime-title">Mattina</div>
        <div *ngFor="let item of morningHoursRemote; let i = index">
          <div class="flex-time">
            <div class="time-table-flex">
              <label>Dalle:</label>
              <select [(ngModel)]="item[1]">
                <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
              </select>
            </div>
            <div class="time-table-flex">
              <label>Alle:</label>
              <select class="select" [(ngModel)]="item[2]">
                <option *ngFor="let am of mattina" [value]="am">{{ am }}</option>
              </select>
            </div>
            <div class="minusSmall" style="color: white" (click)="removeSlot('M', i)">
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </div>
        <div class="add-slot">
          <div class="plusSmall" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'" (click)="addSlot('M')"
            style="color: white">
            <mat-icon>add</mat-icon>
          </div>
        </div>
        <hr />
        <div class="daytime-title">Pomeriggio</div>
        <div *ngFor="let item of afternoonHoursRemote; let i = index">
          <div class="flex-time">
            <div class="time-table-flex">
              <label>Dalle:</label>
              <select [(ngModel)]="item[1]">
                <option *ngFor="let am of pomeriggio" [value]="am">
                  {{ am }}
                </option>
              </select>
            </div>
            <div class="time-table-flex">
              <label>Alle:</label>
              <select class="select" [(ngModel)]="item[2]">
                <option *ngFor="let am of pomeriggio" [value]="am">
                  {{ am }}
                </option>
              </select>
            </div>
            <div class="minusSmall" style="color: white" (click)="removeSlot('P', i)">
              <mat-icon>remove</mat-icon>
            </div>
          </div>
        </div>
        <div class="add-slot">
          <div class="plusSmall" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" [matRippleColor]="'rgb(255, 255, 255, 0.2)'" (click)="addSlot('P')"
            style="color: white">
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </div>

    </div>
  </mat-dialog-content>
  <div>
    <div id="error" *ngFor="let error of errors">
      <p class="error" id="errorName">Errore:</p>
      <br /><span style="font-size: 16px; color: #20623b; display: flex">{{
        error
        }}</span>
    </div>
  </div>
  <mat-dialog-actions align="end" style="margin-top: 10px">
    <div matRipple
      [matRippleCentered]="false"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false"
      class="button-cancel" (click)="close()">
      ANNULLA
    </div>
    <div matRipple
      [matRippleCentered]="false"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false"
      [matRippleColor]="'rgb(255, 255, 255, 0.2)'"
      class="button-save"
      (click)="save()">
      SALVA
    </div>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>

</div>

