import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

addGMaps()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function addGMaps() {
  if (environment.mapsAPIKey) {
    let head = document.getElementsByTagName('head')[0];

    let node = document.createElement('script');
    node.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.mapsAPIKey + '&libraries=places&language=it';
    node.type = 'text/javascript';
    node.async = true;

    head.appendChild(node);
  }
}
