import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MyErrorHandler } from 'src/app/constants/error';
import { GenericConfirmationComponent } from 'src/app/modal/generic-confirmation/generic-confirmation.component';
import { ConfirmationUpdateAgenciesComponent } from 'src/app/modal/modal-agencies/confirmation-update-agencies/confirmation-update-agencies.component';
import { ModalCloseExtraordinaryComponent } from 'src/app/modal/modal-agencies/modal-close-extraordinary/modal-close-extraordinary.component';
import { ModalCloseFestivityComponent } from 'src/app/modal/modal-agencies/modal-close-festivity/modal-close-festivity.component';
import { ModalCreateServicesComponent } from 'src/app/modal/modal-agencies/modal-create-services/modal-create-services.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { Agency, Closings, OpeningDays, OpeningHours, Service, SpecificHoliday, TimeSlots } from 'src/app/model/agency.model';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { AgenciesService } from 'src/app/services/agencies.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

class MyListObj {
  id: number | undefined;
  label: string | undefined;

  constructor(id?: number, label?: string) {
    this.id = id;
    this.label = label;
  }
}

@Component({
  selector: 'app-agency-update',
  templateUrl: './agency-update.component.html',
  styleUrls: ['./agency-update.component.scss']
})
export class AgencyUpdateComponent implements OnInit {

  spinnerView: boolean = true;
  miniSpinnerView: boolean = false;
  private roles: ROLES[] = [];

  agencyList: Array<{id_agency: number, name: string}> = [];
  configurationList: Array<MyListObj> = new Array<MyListObj>();
  configurationDates: Array<string> = new Array<string>();
  generalSettings: any;

  filteredAgencies: Array<{id_agency: number, name: string}> = [];
  inputAgencyName: string = "";
  selectedAgencyId: number | undefined;
  selectedConfigurationId: number | undefined;

  selectedAgency: Agency = new Agency();
  private originalAgency: Agency = new Agency(); // Usata per fare il confronto delle chiusure alla fine

  openings: Array<TimeSlots> = new Array<TimeSlots>();

  status = [{value: "DISABLED", label: "INATTIVA"}, {value: "OPERATIVE", label: "OPERATIVA"}];

  canSeeCalendar: boolean = false;
  isClosedMorning: boolean = false;
  isClosedAfternoon: boolean = false;

  opening_days: OpeningDays = new OpeningDays();

  private dataDecorrenza?: Date;

  maps_options: Options = new Options({
    fields: [
      "formatted_address",
      "geometry",
      "url",
      "address_components"
    ],
    componentRestrictions: {
      country: "it"
    },
    strictBounds: true,
    types: []
  });

  constructor(
    private agencyService: AgenciesService,
    private userService: UserService,
    private utilsService: UtilsService,
    private router: Router,
    private datePipe : DatePipe,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      if(this.roles.includes(ROLES.ADMIN)) {
        this.canSeeCalendar = true;
      }

      this.loadAgenciesList();
    }
  }

  handleAddressChange(address: any) {
    this.selectedAgency.CAP = "";
    this.selectedAgency.city_province = "";
    this.selectedAgency.city_name = "";
    this.selectedAgency.id_city = undefined;
    this.selectedAgency.address = address.formatted_address;

    this.selectedAgency.latitude = address.geometry.location.lat();
    this.selectedAgency.longitude = address.geometry.location.lng();


    for(let i = 0; i < address.address_components.length; i++){
      if (address.address_components[i].types.indexOf("administrative_area_level_3") !== -1){
          this.selectedAgency.city_name = address.address_components[i].long_name;
      }
      else if (address.address_components[i].types.indexOf("administrative_area_level_2") !== -1){
          this.selectedAgency.city_province = address.address_components[i].short_name;
      }
      else if (address.address_components[i].types.indexOf("postal_code") !== -1){
          this.selectedAgency.CAP = address.address_components[i].long_name;
      }
    }
  }

  private loadAgenciesList() {
    this.agencyService.getAgencies().subscribe(
      data => {
        this.agencyList = data.agencies;
        this.filteredAgencies = data.agencies;
        this.generalSettings = data.general_settings;
        this.spinnerView = false;
      },
      err => {
        this.showError(err);
      }
    )
  }

  private loadAgencyData(id_agency: number | undefined, id_configuration?: number) {
    if (id_agency) {
      this.miniSpinnerView = true;

      if (!id_configuration) {
        // Se devo caricare la configurazione attuale

        this.agencyService.getSingleAgency(id_agency).subscribe(
          data => {
            this.populateAgencyData(data);

            this.agencyService.getAllConfigurationsByAgencyId(id_agency).subscribe(
              data => {
                this.configurationList = data.agency_configurations;
                this.configurationDates = new Array<string>();
                this.selectedConfigurationId = undefined;

                this.configurationList.forEach((el, index) => {
                  if(el.label) this.configurationDates.push(el.label);
                  el.label = index == 0 ? "Configurazione attualmente in vigore" : "Decorrenza dal " + el.label;
                });

                this.miniSpinnerView = false;
              },
              err => {
                this.showError(err);
              }
            );
          },
          err => {
            this.showError(err);
          }
        );
      } else {
        // Se devo caricare una specifica configurazione
        this.agencyService.getSingleConfiguration(id_agency, id_configuration).subscribe(
          data => {
            this.populateAgencyData(data);

            this.miniSpinnerView = false;
          },
          err => {
            this.showError(err);
          }
        );
      }
    }
  }

  private populateAgencyData(data: any) {
    this.selectedAgency = new Agency();
    this.originalAgency = new Agency();

    this.selectedAgency = data.agency;
    this.selectedAgency.agency_services = data.agency_metadata.agency_services;
    this.selectedAgency.closings = data.agency_metadata.closings;
    this.selectedAgency.specific_holidays = data.agency_metadata.specific_holidays;
    this.openings = OpeningHours.returnTimeSlots(data.agency_metadata.opening_hours);

    this.isClosedMorning = this.openings[0].isInvalid();
    this.isClosedAfternoon = this.openings[1].isInvalid();

    this.selectedAgency.future_settings = data.agency_metadata.future_settings;
    this.parseGeneralOpenings();

    this.resetDate();

    this.originalAgency.closings = JSON.parse(JSON.stringify(this.selectedAgency.closings));
    this.originalAgency.specific_holidays = JSON.parse(JSON.stringify(this.selectedAgency.specific_holidays));
    this.originalAgency.future_settings = JSON.parse(JSON.stringify(this.selectedAgency.future_settings));
  }

  doFilter() {
    this.filteredAgencies = this.agencyList.filter(agency_obj => {return agency_obj.name.toLowerCase().includes(this.inputAgencyName.toLowerCase())});
  }

  selectAgency() {
    this.selectedAgencyId = this.agencyList.find(el => {return el.name == this.inputAgencyName})?.id_agency;
    this.loadAgencyData(this.selectedAgencyId);
  }

  reloadAgency() {
    this.utilsService.scrollTop();
    this.loadAgencyData(this.selectedAgencyId);
  }

  selectConfiguration() {
    if(this.selectedConfigurationId == this.configurationList[0].id) {
      this.reloadAgency();
    } else {
      this.loadAgencyData(this.selectedAgencyId, this.selectedConfigurationId);
    }
  }

  parseGeneralOpenings() {
    let chars: any[];
    if(this.selectedAgency.future_settings.opening_morning) {
      chars = [...this.selectedAgency.future_settings.opening_morning];
    } else {
      chars = [...this.generalSettings.openings];
    }

    chars.forEach(
      (el, index) => {
        this.opening_days.morning[index] = el == '0';
      }
    );

    if(this.selectedAgency.future_settings.opening_afternoon) {
      chars = [...this.selectedAgency.future_settings.opening_afternoon];
    } else {
      chars = [...this.generalSettings.openings];
    }

    chars.forEach(
      (el, index) => {
        this.opening_days.afternoon[index] = el == '0';
      }
    );
  }

  resetDate() {
    this.dataDecorrenza = new Date();
  }

  openModal(value: string) {
    switch (value) {
      case 'service': {
        let newService: Service = new Service();

        const dialogRef = this.dialog.open(ModalCreateServicesComponent, {
          data: {
            services: this.generalSettings.services,
            newService: newService
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.agency_services = this.selectedAgency.agency_services.filter(el => el.id_service != result.id_service);
            this.selectedAgency.agency_services.push(result);
          }
        });
      }
      break;

      case 'specific_holidays': {
        let newFestivity: SpecificHoliday = new SpecificHoliday();
        const dialogRef = this.dialog.open(ModalCloseFestivityComponent, {
          data: newFestivity
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.specific_holidays = this.selectedAgency.specific_holidays.filter(el => el.date != result.date);
            this.selectedAgency.specific_holidays.push(result);
          }
        });
      }
      break;

      case 'closing': {
        let newClosing: Closings = new Closings();
        const dialogRef = this.dialog.open(ModalCloseExtraordinaryComponent, {
          data: newClosing
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectedAgency.closings.push(result);
          }
        });
      }
      break;

      case 'save': {
        const dialogRef = this.dialog.open(ConfirmationUpdateAgenciesComponent, {
          data: this.configurationDates
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.dataDecorrenza = result;
            this.saveButton();
          }
        });
      }
      break;

      case 'pre-save': {
        let message = "Attenzione! Se si prosegue con la modifica, eventuali appuntamenti esistenti nei nuovi giorni di chiusura impostati verranno eliminati. I clienti riceveranno in automatico un'email di avvenuta cancellazione."
        const dialogRef = this.dialog.open(GenericConfirmationComponent, {
          data: {
            message: message
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.openModal("save");
          }
        });
      }
      break;
    }

  }

  typeAgency(value: string) {
    return value == "ALL_DAY"? "TOTALE" : value == "M"? "MATTINA" : "POMERIGGIO";
  }

  removeService(id: number | undefined) {
    if(id)
      this.selectedAgency.agency_services = this.selectedAgency.agency_services.filter(el => el.id_service != id);
  }

  removeFestivity(date: string | null) {
    if(date)
      this.selectedAgency.specific_holidays = this.selectedAgency.specific_holidays.filter(el => el.date != date);
  }

  removeClosingDays(value: Closings) {
    if(value.start_date && value.end_date) {
      this.selectedAgency.closings = this.selectedAgency.closings.filter(el => (
        !(el.start_date == value.start_date && el.end_date == value.end_date && el.type == value.type)
      ));
    }
  }

  isProvinceInvalid(): boolean {
    return !(this.selectedAgency.city_province && this.selectedAgency.city_province.length == 2 && /[A-Z]/.test(this.selectedAgency.city_province));
  }

  isCAPInvalid(): boolean {
    return !(this.selectedAgency.CAP && this.selectedAgency.CAP.length == 5 && /^\d+$/.test(this.selectedAgency.CAP));
  }

  isAgencyCodeInvalid(): boolean {
    return !(this.selectedAgency.CODE && this.selectedAgency.CODE.length <= 5 && /^\d+$/.test(this.selectedAgency.CODE));
  }

  isOpeningHoursInvalid(): boolean {
    return this.isClosedMorning && this.isClosedAfternoon && this.selectedAgency.future_settings.status == "OPERATIVE";
  }

  isConfigurationView(): boolean {
    return this.selectedConfigurationId ? true : false;
  }

  isSaveDisabled(): boolean {
    return (
      !this.selectedAgency.name ||
      !this.selectedAgency.city_name ||
      this.isProvinceInvalid() ||
      this.isCAPInvalid() ||
      !this.selectedAgency.address ||
      this.isAgencyCodeInvalid() ||
      (!this.isClosedMorning && this.openings[0].isInvalid()) ||
      (!this.isClosedAfternoon && this.openings[1].isInvalid()) ||
      this.isOpeningHoursInvalid() ||
      (this.selectedAgency.future_settings.status == "OPERATIVE" && !this.selectedAgency.google_calendar_account) ||
      !this.selectedAgency.future_settings.status ||
      !this.dataDecorrenza
    );
  }

  private saveButton() {
    if (!this.selectedAgencyId) {
      return
    } else {
      this.spinnerView = true;
      this.selectedAgency.future_settings.opening_hours = new Array<OpeningHours>();

      if (!this.isClosedMorning) {
        let oh = new OpeningHours();
        oh.buildFromTimeSlot(this.openings[0]);
        this.selectedAgency.future_settings.opening_hours.push(oh);
      }

      if (!this.isClosedAfternoon) {
        let oh = new OpeningHours();
        oh.buildFromTimeSlot(this.openings[1]);
        this.selectedAgency.future_settings.opening_hours.push(oh);
      }

      let days = OpeningDays.buildOpeningDaysFromOpeningDays(this.opening_days);

      this.selectedAgency.future_settings.opening_morning = days[0];
      this.selectedAgency.future_settings.opening_afternoon = days[1];

      let date = this.datePipe.transform(this.dataDecorrenza, 'yyyy-MM-dd');
      if (date) this.selectedAgency.future_settings.start_operative = date;

      this.agencyService.updateSingleAgency(this.selectedAgencyId, this.selectedAgency).subscribe(
        data => {
          this.showSuccess();

          this.spinnerView = false;
        },
        err => {
          this.showError(err);

          this.spinnerView = false;
        }
      );
    }
  }

  deleteButton() {
    if(this.selectedConfigurationId == this.configurationList[0].id || !this.selectedAgencyId) return

    this.spinnerView = true;

    this.agencyService.deleteSingleConfiguration(this.selectedAgencyId, this.selectedConfigurationId).subscribe(
      data => {
        this.showSuccess();

        this.spinnerView = false;
      },
      err => {
        this.showError(err);

        this.spinnerView = false;
      }
    );
  }

  // Controlla se esistono nuove chiusure di qualsiasi tipo (giorni, feste, etc..) e apre la modale
  checkNewClosingsAndSave() {
    let hasNewClosings = false;

    // Controlla i giorni di chiusura
    let newOpeningDays = OpeningDays.buildOpeningDaysFromOpeningDays(this.opening_days);

    for (let i = 0; i < 7; i++) {
      let tmp_morning_original = this.originalAgency.future_settings.opening_morning![i];
      let tmp_morning_new = newOpeningDays[0][i];

      let tmp_afternoon_original = this.originalAgency.future_settings.opening_afternoon![i];
      let tmp_afternoon_new = newOpeningDays[1][i];

      if (
          (tmp_morning_original == '1' && tmp_morning_new == '0')
          ||
          (tmp_afternoon_original == '1' && tmp_afternoon_new == '0')
        ) {
        hasNewClosings = true;
        break;
      }
    }

    // Controlla lo status della filiale
    if (this.originalAgency.future_settings.status == 'OPERATIVE' && this.selectedAgency.future_settings.status == 'DISABLED') {
      hasNewClosings = true;
    }

    // Controlla se ci sono Closing nuove in this.selectedAgency
    let newClosings = this.selectedAgency.closings.filter(el => {
      // Controlla che 'el' non sia contenuto in this.originalAgency
      return !this.originalAgency.closings.some(el2 => {
        return (el.type == el2.type && el.start_date == el2.start_date && el.end_date == el2.end_date)
      });
    });

    if (newClosings.length != 0) hasNewClosings = true;

    console.log(hasNewClosings);

    // Controlla se ci sono Specific Holidays nuove in this.selectedAgency
    let newSpecificHolidays = this.selectedAgency.specific_holidays.filter(el => {
      // Controlla che 'el' non sia contenuto in this.originalAgency
      return !this.originalAgency.specific_holidays.some(el2 => {
        return (el.date == el2.date)
      });
    });

    if (newSpecificHolidays.length != 0) hasNewClosings = true;

    console.log(hasNewClosings);

    if (hasNewClosings) {
      this.openModal('pre-save');
    } else {
      this.openModal('save')
    }
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.AGENCY.path]);
        break;
      case 'here':
        //this.router.navigate([ROUTES.AGENCY_UPDATE.path]);
        this.reloadAgency();
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('here');
    });
  }

  private showSuccess() {
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('here');
    });
  }

}
