import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MyErrorHandler } from 'src/app/constants/error';
import { ConfirmationConsultantComponent } from 'src/app/modal/modal-consultant/confirmation-consultant/confirmation-consultant.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { Consultant } from 'src/app/model/consultant.model';
import { ROUTES } from 'src/app/route/routes';
import { AgenciesService } from 'src/app/services/agencies.service';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-associazioni-filiali',
  templateUrl: './associazioni-filiali.component.html',
  styleUrls: ['./associazioni-filiali.component.scss']
})
export class AssociazioniFilialiComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['agency_code', 'agency_name', 'consultant_name', 'consultant_surname', 'matricola', 'email', 'action' ];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  agencyList: Array<{id_agency: number, name: string}> = [];

  filteredAgencies: Array<{id_agency: number, name: string}> = [];
  inputAgencyName: string = "";

  viewForm: boolean = false;
  spinnerView: boolean = true;

  consultant: Consultant = new Consultant();

  constructor(
    public router: Router,
    private consultantService: ManagerService,
    private agencyService: AgenciesService,
    public dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.getConsultants();
    this.loadAgenciesList();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  private getConsultants() {
    this.consultantService.getConsultants().subscribe(res => {
      this.dataSource.data = res.consultants;
      //this.dataSource.sort = this.sort;
      this.spinnerView = false;
    });

    //this.changeDetectorRefs.detectChanges();
  }

  private loadAgenciesList() {
    this.agencyService.getAgencies().subscribe(
      data => {
        this.agencyList = data.agencies;
        this.filteredAgencies = data.agencies;
      },
      err => {
        this.showError(err);
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  doFilterAutocomplete() {
    this.filteredAgencies = this.agencyList.filter(agency_obj => {return agency_obj.name.toLowerCase().includes(this.inputAgencyName.toLowerCase())});

    this.consultant.id_agency = undefined;
  }

  selectAgency() {
    this.consultant.id_agency = this.agencyList.find(el => {return el.name == this.inputAgencyName})?.id_agency;
  }

  showForm() {
    this.viewForm = true;
  }

  resetForm() {
    this.viewForm = false;
    this.inputAgencyName = "";
    this.filteredAgencies = this.agencyList;
    this.consultant = new Consultant();
  }

  isSaveDisabled() {
    return (
      !this.consultant.id_agency ||
      !this.consultant.name ||
      !this.consultant.surname ||
      !this.consultant.email ||
      !this.consultant.matricola
    );
  }

  save() {
      this.spinnerView = true;

      this.consultantService.createConsultant(this.consultant).subscribe(
        res => {
          if (res) {
            this.spinnerView = false;
            this.showSuccess();
            this.resetForm();
            this.getConsultants();
          }
        },
        err => {
          this.showError(err);
        }
      );
  }

  removeConsultant(element: any) {
    console.log(element.id_consultant_operator)
    let id = element.id_consultant_operator;
    const dialogRef = this.dialog.open(ConfirmationConsultantComponent, {
      data: element
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinnerView = true;
        this.consultantService.deleteConsultant(id).subscribe(
          res => {
            this.showSuccess();
            this.resetForm();
            this.getConsultants();
          },
          err => {
            this.showError(err);
          }
        )
      }
    });
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.CONSULTANT.path]);
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  private showSuccess() {
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
      //this.goToHome();
    });
  }
}
