<div *ngIf="loading">
    <div class="intestazione-container">
        <img class="image-footer" src="./../../assets/logo_Credem.jpg">
      </div>
    <div class="spinner-loading" >
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
  </div>
<div class="pre-landing" *ngIf="!loading">
      <div class="title-container">
          <h2 style="font-size: 24px;color: #20623b;">Prenota Appuntamento</h2>
      </div>
      <div class="sub-title-container">
          <h4>Pannello di Amministrazione</h4>
      </div>
      <div class="image-container">
      <img src="./../../assets/calendar.png" alt="calendar" />
      </div>
      <div class="btn-container">
          <button class="btn-submit" (click)="goToHome()" style="margin-top: 20px;">Inizia</button>
      </div>
</div>