<div class="modal">
  <div>
    <label>Seleziona una data di decorrenza per le modifiche applicate</label>
  </div>

  <mat-radio-group [(ngModel)]="isDecorrenzaImmediata">
    <div class="my-row following-row">
      <div class="my-row-element-1">
        <mat-radio-button class="radio-margin" [value]="true" (change)="resetDate()">
          Decorrenza Immediata
        </mat-radio-button>
      </div>
    </div>

    <div class="my-row following-row">
      <div class="my-row-element-2">
        <mat-radio-button class="radio-margin" [value]="false">
          Decorrenza con data futura
        </mat-radio-button>
        <mat-form-field>
          <mat-label>Inserisci la data</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="dataDecorrenza" [min]="minDate" name="datepicker"
            style="border-bottom: none;" [disabled]="isDecorrenzaImmediata" (ngModelChange)="checkDecorrenza()">
          <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="isDecorrenzaImmediata"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-radio-group>

  <div class="my-row" *ngIf="!isDecorrenzaImmediata && isDataDecorrenzaEsistente">
    <div class="my-row-element-1">
      <div class="errorInputAgency">
        <p>Attenzione. Esiste già una configurazione per la data selezionata. In caso di conferma, verrà sovrascritta.</p>
      </div>
    </div>
  </div>

  <div class="my-row following-row">
    <div class="my-row-element-1">
      <p class="legend">
        N.B. In caso di decorrenza differita, gli unici campi che avranno effettivamente tale data di decorrenza sono:<br>
        - Orario Mattutino e Orario Pomeridiano;<br>
        - Stato della filiale (operativa/inattiva);<br>
        - Chiusure settimanali.<br>
        Tutte le altre modifiche avranno decorrenza immediata.<br>
      </p>
    </div>
  </div>

  <div class="my-button-container">
    <button class="my-button back" mat-raised-button (click)="close()">
      ANNULLA
    </button>
    <button class="my-button confirm" mat-raised-button (click)="save()" [disabled]="!isDecorrenzaImmediata && !dataDecorrenza">
      CONFERMA
    </button>
  </div>
</div>