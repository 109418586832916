export class Agency {
    CAP: string | undefined;
    CODE: string | undefined;
    address: string | undefined;
    city_name: string | undefined;
    city_province: string | undefined;
    //city_region: string | undefined;
    longitude: string | undefined;
    latitude: string | undefined;
    agency_services: Array<Service>;
    closings: Array<Closings>;
    specific_holidays: Array<SpecificHoliday>;

    google_calendar_account: string | undefined;
    future_settings: {
        id_configuration?: number,
        opening_afternoon: string | undefined,
        opening_morning: string | undefined,
        opening_hours: Array<OpeningHours>,
        status: string | undefined,
        start_operative?: string
    };

    id_agency: number | undefined;
    id_city: number | undefined;
    name: string | undefined;

    constructor() {
        this.agency_services = [];
        this.closings = [];
        this.specific_holidays = [];
        this.future_settings = {
            opening_afternoon: undefined,
            opening_morning:  undefined,
            opening_hours: [],
            status: undefined
        }
    }
}

export class OpeningHours {
    end_time!: string;
    start_time!: string;

    buildFromTimeSlot(slot: TimeSlots): void {
        this.start_time = slot.start.hour + ":" + slot.start.minute;
        this.end_time = slot.end.hour + ":" + slot.end.minute;
    }

    static returnTimeSlots(openingHours: Array<OpeningHours>): Array<TimeSlots> {
        let slot1: TimeSlots = new TimeSlots();
        let slot2: TimeSlots = new TimeSlots();
        let res: Array<TimeSlots> = new Array<TimeSlots>();

        openingHours.forEach(el => {
            let start = el.start_time.split(":");
            let end = el.end_time.split(":");

            if(parseInt(start[0]) < 14) {
                slot1.start = new Slot(start[0], start[1]);
                slot1.end = new Slot(end[0], end[1]);
            } else {
                slot2.start = new Slot(start[0], start[1]);
                slot2.end = new Slot(end[0], end[1]);
            }
        });

        res.push(slot1);
        res.push(slot2);

        return res;
    }
}

export class Closings {
    end_date: string | null;
    type!: string;
    start_date: string | null;

    constructor() {
        this.start_date = null;
        this.end_date = null;
    }
}

export class Service {
    id_service?: number;
    name?: string;
    value: number | null;

    constructor() {
        this.value = null;
    }
}

export class SpecificHoliday {
    id?: number;
    date: string | null;
    festivity?: string;

    constructor() {
        this.date = null;
    }
}

class Slot {
    hour: string | null;
    minute: string | null;

    constructor(hour?: string, minute?: string) {
        this.hour = hour? hour : null;
        this.minute = minute? minute : null;
    }
}

export class TimeSlots {
    start: Slot;
    end: Slot;

    constructor(start?: Slot, end?: Slot) {
        this.start = new Slot(start?.hour!, start?.minute!);
        this.end = new Slot(end?.hour!, end?.minute!);
    }

    reset() {
        this.start = new Slot();
        this.end = new Slot();
    }

    isInvalid(): boolean {
        return (
            !this.start.hour ||
            !this.start.minute ||
            !this.end.hour ||
            !this.end.minute ||
            parseInt(this.end.hour) < parseInt(this.start.hour) ||
            (
                parseInt(this.end.hour) == parseInt(this.start.hour)
                &&
                parseInt(this.end.minute) <= parseInt(this.start.minute)
            )
          );
    }
}

export class OpeningDays {
    morning: boolean[];
    afternoon: boolean[];

    constructor() {
        this.morning = [false, false, false, false, false, false, false],
        this.afternoon = [false, false, false, false, false, false, false]
    }

    static buildOpeningDaysFromOpeningDays(openingDays: OpeningDays) {
        let opening_morning = "";
        openingDays.morning.forEach(el => {let day = el? "0" : "1"; opening_morning+=day});

        let opening_afternoon = "";
        openingDays.afternoon.forEach(el => {let day = el? "0" : "1"; opening_afternoon+=day});

        return [opening_morning, opening_afternoon];
    }
}
