import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpecificHoliday } from 'src/app/model/agency.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modal-close-festivity',
  templateUrl: './modal-close-festivity.component.html',
  styleUrls: ['./modal-close-festivity.component.css'],
})
export class ModalCloseFestivityComponent implements OnInit {

  private newDate = new Date();
  minDate: Date = new Date(this.newDate.getFullYear(), 0, 1);
  maxDate: Date = new Date(this.newDate.getFullYear(), 11, 31);

  name: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<ModalCloseFestivityComponent>,
    private datePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public inputData: SpecificHoliday
  ) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

  saveData() {
    let date = this.datePipe.transform(this.inputData.date, 'dd-MM');
    this.inputData.date = date;
    this.dialogRef.close(this.inputData);
  }
}
